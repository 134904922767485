<template lang="pug">
.videocall
  div(
    v-for="(video, i) in vidsWithSelector"
    class="videoContainer"
    v-show="!haveSharedVideo || video.isContent"
    :class="{ firstVideo: isfirstVideo(vidsWithSelector, video) === 'first', secondVideo: isfirstVideo(vidsWithSelector, video) === 'second', minivideoSharedLeft: isShared(vidsWithSelector, video) === 'left', minivideoSharedRight: isShared(vidsWithSelector, video) === 'right', minicontentShared: isShared(vidsWithSelector, video) === 'shared', minicontentHidden: isShared(vidsWithSelector, video) === 'hide' }"
  )
    video(
      :key="video.selector"
      :id="video.selector"
    ).Video
    //- img(class="camChange" :src="camChange" v-show="video.localTile && video.active" @click="$emit('CamChange')")
  audio(:id="audioIdFinal" style="display: none")
</template>

<script>
import kuid from "kuid";
import camChange from "@/assets/CamChange.png";

export default {
  name: "videocall",

  props: {
    audioId: {
      default: null
    },
    videosIds: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    audioIdLocal: `audio_${kuid()}`,
    camChange: camChange
  }),

  mounted() {
    this.$emit("audioIdGenerated", this.audioIdLocal);
  },

  watch: {
    vidsWithSelector() {
      this.controlsChecker();
    }
  },

  computed: {
    audioIdFinal() {
      return this.audioId || this.audioIdLocal;
    },

    vidsWithSelector() {
      return this.videosIds.map(vid => {
        vid.selector = `videouser_${vid.tileId}`;
        return vid;
      });
    },

    haveSharedVideo() {
      return !!this.videosIds.find(vid => vid.isContent);
    },

    changeVideo() {
      return this.videosIds;
    }
  },

  methods: {
    isfirstVideo(list, item) {
      if (list.find(elm => elm.isContent)) return;
      let firstTileWithoutLocal = null;
      for (let i = 0; i < list.length; i++) {
        const elm = list[i];
        if (firstTileWithoutLocal) break;
        else if (!elm.localTile) firstTileWithoutLocal = elm.tileId;
      }
      return !firstTileWithoutLocal || item.tileId === firstTileWithoutLocal
        ? "first"
        : "second";
    },

    isShared(list, item) {
      if (!list.find(elm => elm.isContent)) return;
      if (item.isContent) {
        let elmIdx = list
          .filter(elm => elm.isContent)
          .findIndex(elm => elm.tileId === item.tileId);
        if (elmIdx === 0) return "shared";
        else return "hide";
      } else {
        let elmIdx2 = list
          .filter(elm => !elm.isContent)
          .findIndex(elm => elm.tileId === item.tileId);
        if (elmIdx2 === 0) return "right";
        else return "left";
      }
    },

    controlsChecker() {
      this.$nextTick().then(() => {
        for (const video of this.vidsWithSelector) {
          if (video.isContent)
            document.getElementById(video.selector).controls = true;
        }
      });
    }
  }
};
</script>

<style scoped>
.videocall {
  width: 100%;
  height: 100%;
  /* min-width: 200px; */
  /* max-height: 200px; */
  position: relative;
  border-radius: 20px;
  background-color: var(--color-dark);
}

.videoContainer {
  width: 100%;
  height: 100%;
  position: absolute;
}

video {
  width: 100%;
  height: 100%;
  border-radius: 17px;
}

.camChange {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 24%;
  max-width: 40px;
  z-index: 2;
}

.secondVideo {
  position: absolute;
  margin: 10px;
  right: 0;
  bottom: 0;
  width: 218.22px;
  height: 144px;
  object-fit: contain;
  object-position: center;
  z-index: 2;
}

.secondVideo video {
  border-radius: 20px;
}

.firstVideo {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
  object-position: center;
  z-index: 1;
}

.minivideoSharedLeft {
  position: absolute;
  margin: 10px;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  object-fit: contain;
  object-position: center;
  z-index: 2;
}

.minivideoSharedRight {
  position: absolute;
  margin: 10px;
  right: 0;
  top: 0;
  width: 80px;
  height: 80px;
  object-fit: contain;
  object-position: center;
  z-index: 2;
}

.minicontentShared {
  position: absolute;
  width: calc(100% - 10px);
  height: 100%;
  bottom: 0;
  left: 0;
  margin: 5px;
  object-fit: contain;
  object-position: center;
  z-index: 1;
}

.minicontentHidden {
  display: none;
  position: absolute;
  width: calc(100% - 10px);
  height: calc(100% - 130px);
  bottom: 0;
  left: 0;
  margin: 5px;
  object-fit: contain;
  object-position: center;
  z-index: 1;
}
</style>
