<template lang="pug">
div
  //- div(style="display:none;position:absolute") {{turn}}
  .mx-auto.max-width-box-form(v-show="!showMeet")
    div( style="width: 100%; text-align: center;" v-if="preload")
      Spinner
      h3 Conectando...
    div(v-show="!preload")
      audio#attended(@ended="soundEmmited")
        source(src="@/assets/sounds/colmedica_audio.mp3" type="audio/mpeg")
      audio#advice
        source(src="@/assets/sounds/turno_audio.mp3" type="audio/mpeg")
      Header(v-if="!showNotHourToTurn && !showExpired && !showExpiredLink && !showCanceled")
      .Page(:style="!showVideosTikTok ? 'bottom: 101px' : ''")
        .TurnPage(:style="(showVideosTikTok || shwoEnded) && 'bottom: 0; position: absolute;'")
          .main_box.pt-4(v-if="!showVideosTikTok && !showNotHourToTurn && !showExpired && !shwoEnded && !showExpiredLink && !showCanceled")
            h2.Title ¡Turno asignado! {{username}}
            .px-4
              .boxInfo.col-12.mt-4.p-4(style="z-index: 1;")
                h4.text-center.AssignmentText.Subtitle Tu turno asignado es:
                //- h4.text-middle.color-colmedica-gray número:
                h3.text-center.font-weight-bold.ImportantAsignment.Subtitle {{ assingTurn }}
                h5(v-show="!initMeet").mt-1.text-center.mb-1.Subtitle Tú tienes prioridad, espera en la línea para ser atendido.
                //- h5(v-show="!initMeet").text-middle.font-larger.Subtitle {{ typeof turnsInFrontBSIX === "number" ? turnsInFrontBSIX + 1 : "1" }}
                //- h5(v-show="!initMeet").mt-1.text-center.mb-1 Próximo turno:
                //- h5(v-show="!initMeet").text-middle.font-larger {{ actualTurn }}
                h5(v-show="initMeet").mt-5.text-middle.color-colmedica-gray Un momento por favor...
                //- p Te enviaremos un correo electrónico y un mensaje de texto con un link de ingreso a la videollamada cuando tu turno este próximo  a ser atendido
                //- p.buttonText Te enviaremos un mensaje de texto con un link de ingreso a la videollamada cuando tu turno sea próximo a ser atendido.
                //- //- a.Link La notificación te la enviaremos a tu correo electrónico {{email}} y a tu número celular {{phone}}
                //- a.Link.buttonText La notificacion te la enviaremos a tu número celular {{phone}}
              p.mt-4.buttonText ¡Esperemos juntos!
              p.mb-0.buttonText A continuacion hemos preparado una serie de contenidos para ti
          //- div(class="text-right my-3") {{ version }}
          div(v-if="showVideosTikTok")
            TikTokVideos(
              :assignTurn="assingTurn"
              :actualTurn="actualTurn"
              :turnsInFront="turnsInFrontBSIX"
              :goBack="() => showVideosTikTok = false"
            )
          div(v-if="shwoEnded")
            .d-flex.justify-content-between.align-items-center
              img(style="width: 28px; opacity: 0;" src="@/assets/kiosco46/arrow.svg")
              TimerFinished(:goHomeReal="goHomeReal" :containerImageDocs="containerImageDocs" :timer="timer" :showProgressBar="showProgressBar")
            .mx-auto.max-width-box-form
              svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg').text-center.d-block.mx-auto.mt-5
                path(d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -6.26249e-07 16 -1.39876e-06C7.16345 -2.17128e-06 2.17128e-06 7.16344 1.39876e-06 16C0.00937525 24.8326 7.16733 31.9906 16 32Z' fill='#3290E0')
                path(d='M15.9987 24.9805C17.1446 24.9805 18.0117 24.3824 18.0117 23.4165L18.0117 14.5565C18.0117 13.5895 17.1448 12.9935 15.9987 12.9935C14.8807 12.9935 13.9856 13.6144 13.9856 14.5565L13.9856 23.4165C13.9857 24.3595 14.8807 24.9805 15.9987 24.9805ZM15.9987 10.9974C17.0997 10.9974 17.9957 10.1014 17.9957 8.99949C17.9957 7.8995 17.0997 7.00344 15.9987 7.00344C14.8977 7.00344 14.0026 7.8995 14.0026 8.99949C14.0027 10.1015 14.8977 10.9974 15.9987 10.9974Z' fill='#F4F4F4')
              h2.mt-3.TitleSection.px-4.pb-3 La solicitud de la videollamada ha finalizado
              .form-data.mb-3.mt-5.py-4.mx-1.px-4.mx-4
                p.font-16.mb-5.text-center.px-4 Gracias por utilizar nuestros servicios. Te esperamos en una próxima ocasión.
                p.font-18.mb-5.text-center.px-4 Recuerda que también puedes solicitar tus autorizaciones médicas mediante nuestros canales virtuales App y Web Colmédica de manera rápida y segura.
          .px-2(v-if="showNotHourToTurn && !showExpired && !shwoEnded")
            Header.mt-0
            h2.mt-5.TitleSection.px-4.pb-3 Aún no es tiempo para tu videollamada
            .form-data.mb-3.mt-5.py-4.mx-1.px-4.mx-3
              p.font-16.mb-0.text-start Nombre:
                strong.ml-1 {{ codeInfo.customer.firstName }}
              p.font-16.mb-0.text-start Fecha y hora:
                strong.ml-1 {{ dateWithoutHour }}
                strong.ml-2 {{ hour }}
              p.font-16.mb-0.text-start Servicio:
                strong.ml-1 {{ codeInfo.reason.servicioSelected }}
              p.font-16.mb-0.text-start Modalidad de atención:
                strong.ml-1 {{ codeInfo.reason.modalidad }}
              p.font-16.mt-4.text-center.text-center Tu videollamada inicia en:
                strong.ml-1.d-block(v-if="duration.asHours() < 24")
                  span(v-if="parseInt(duration.asHours()) > 0") {{ parseInt(duration.asHours()) }} {{ parseInt(duration.asHours()) === 1 ? "hora" : "horas" }}
                  span(v-if="parseInt(duration.asHours()) < 1") {{ parseInt(duration.asMinutes()) }} {{ parseInt(duration.asMinutes()) === 1 ? "minuto" : "minutos" }}
                strong.ml-1.d-block(v-else) {{ parseInt(duration.asDays()) }} {{ parseInt(duration.asDays()) === 1 ? "día" : "días" }}
            p.mt-4.text-center.buttonText ¡Esperemos juntos!
            p.mb-0.text-center.buttonText A continuación hemos preparado una serie de contenidos para ti
          //- .main_bottom.mt-5.pt-5(v-if="showExpired || shwoEnded")
          //-   b-button.mb-0(variant="whitecolmedica" block size="lg" type="button" @click="goHome" style="width: fit-content; border-radius: 24px;").text-center.AuthsBtn.mt-4
          //-     p.buttonText.AuthMeds.mb-0 Ya puedes cerrar ésta ventana
          .main_bottom.mx-3(v-if="!showVideosTikTok && !showExpired && !shwoEnded")
            //- div(v-show="1>2" class="div_center_button" style="bottom: 14vh;")
            //-   b-button(v-b-modal.wait-modal variant="bluecolmedica" class = "modal_button notificationSMSDialog") Notifícame cuando sea mi turno
            b-button.mb-0(variant="bluecolmedica" block size="lg" type="button" @click="showVideosTikTok = true" style="width: fit-content;").text-center.AuthsBtn.mt-4
              p.buttonText.AuthMeds.mb-0 Muéstrame los videos
            b-button(block size="lg" type="button" v-b-modal.cancel-modal v-if="!showVideosTikTok && !showNotHourToTurn").text-center.AuthsBtn.AuthsBtnDanger.mt-4.mb-2
              p.color-danger.buttonText.m-0 Cancelar Solicitud
      b-modal(id="msm-modal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer)
        div(class="d-block text-center modal_inside" )
          p.Title ¡Listo!
          p(class="my-4") Te llegará un mensaje de texto en {{waiting_time}} aproximadamente
          p(class="my-4") Ya puedes cerrar esta ventana
        div(class="div_center_button")
          b-button(@click="$bvModal.hide('msm-modal')" variant="outline-dark" class="div_center_button") Ok
      div(v-if="!showNotHourToTurn && !showExpired && !shwoEnded && !showExpiredLink && !showCanceled && !initMeet")
        b-modal(id="small-modal-dk" v-model="showUltimateModal2" v-if="!initMeet" centered=true hide-footer hide-header no-close-on-backdrop)
          .p-5
            .timer-container(@click="onCancelTurn")
              TimerComponent(:timer="timerAun" :showProgressBar="showProgressBarAun")
            h3.Title.text-center
              strong.normalFont.strong ¿Aún estás ahí?
            b-button(variant="bluecolmedica" block size="lg" type="button" @click="restartTimerWaiting" style="width: fit-content;").text-center.AuthsBtn.mb-4.mt-5
              p.buttonText.AuthMeds.mb-0 Sí, continuar
            .pt-3
              BottomTimer(:doThis="onCancelTurn")

      b-modal(id="cancel-modal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="p-2 position-relative w-auto h-auto")
          div(class="d-block text-center modal_inside" )
            p.Title Estás a punto de finalizar la solicitud de la videollamada
            p.my-4.buttonText ¿Deseas continuar esperando al asesor Colmédica?
          div(class="position-relative")
            b-button(variant="outline-danger" block size="lg" type="button" @click="cancelTurnHandler()" style="border-radius: 40px; width: 562px;").text-center.AuthsBtn.my-4
              p.buttonText.AuthMeds.mb-0 Cancelar la solicitud
            b-button(variant="bluecolmedica" block size="lg" type="button" @click="$bvModal.hide('cancel-modal')" style="width: 562px;").text-center.AuthsBtn.my-4
              p.buttonText.AuthMeds.mb-0 Continuar esperando
        .p-3(v-if="!showUltimateModal2")
          BottomTimer(:doThis="onShowAunSiguesAlli")

      b-modal(id="new-turn-modal" footerClass='p-2 pt-1 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header no-close-on-backdrop)
        div(class="d-block text-center modal_inside" )
          p.mb-4.mt-2.Title Te hemos asignado el turno
            h3.text-middle.color-colmedica-light.font-weight-bold.Subtitle {{ assingTurn }}
          div(class="position-relative")
            b-button(variant="bluecolmedica" block size="lg" type="button" @click="continueTurn" style="width: fit-content;").text-center.AuthsBtn.my-4
              p.buttonText.AuthMeds.mb-0 Continuar
        .p-3(v-if="!showUltimateModal2")
          BottomTimer(:doThis="onShowAunSiguesAlli")
      b-modal(footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header no-close-on-backdrop)#confirm-modal
        div(class="d-block text-center modal_inside" )
          p.my-4.Title Tu asesor esta listo para atenderte
            //- p(class="my-4") Continuar con el asesor Colmedica
          div(class="position-relative")
            b-button(@click="resolveconfirm" variant="bluecolmedica" class="btn-block" style="height: 72px;") Conectar con asesor
  .ContainerMeetingOf(v-if="initMeet")
    Meeting(v-show="showMeet" meetType="customer" :meetId="meetingId" @endMeet="endMeet" :username="username" :isComponent="true" @attendees="attendees")
</template>

<script>
import { mapState, mapActions } from "vuex";
// import Mobile from "../utils/evalmobile";
import _get from "lodash/get";
import kuid from "kuid";
import moment from "moment";

import LogoColmedica from "@/assets/colmedica_logo.png";
import asterisker from "@/helpers/asteriskHidden";

import Meeting from "./Meeting.vue";
import TikTokVideos from "./components/TikTokVideos";
import Header from "./components/Header.vue";
import { setCorrelation } from "../../helpers/Meet";
import { getOneUseToken } from "../../helpers/Meet";
import { utf8_to_b64 } from "@/utils/decoder";
// import LS from "@/helpers/localStorage";
import Spinner from ".././components/Spinner.vue";
import BottomTimer from "./components/BottomTimer";
import TimerComponent from "./components/TimerComponent";
import TimerFinished from "./components/TimerFinished";

const axios = require("axios");

export default {
  name: "Turno",

  components: {
    Meeting,
    TikTokVideos,
    Header,
    Spinner,
    BottomTimer,
    TimerComponent,
    TimerFinished
  },

  data: () => ({
    name: "Susan Fajardo",
    date: "13/02/2022, 9:00a.m.",
    serviceData: "Cardiologia",
    modeAtt: "Videollamada",
    showExpired: false,
    shwoEnded: false,
    showExceededTime: false,
    showUltimateModal: false,
    showNotHourToTurn: false,
    showVideosTikTok: false,
    showExpiredLink: false,
    showUltimateModal2: false,
    showCanceled: false,
    LogoColmedica,
    timerSound: null,
    lastTimerSound: null,
    audioNotification: true,
    // isiOS: Mobile.iOS(),
    isiOS: true,
    turnsInFrontCache: null,
    waitingTimeCache: null,
    timeAverageDirect: false, // TODO: Change this value if require to show DebQ api average time result
    errorCoorelationRequest: false,
    errorStartup: false,

    // meet
    initMeet: false,
    showMeet: true,
    showAudioNotification: true,

    connected: false,
    loading: false,

    resolveconfirm: null,
    resolveNewturn: null,

    codeInfo: {},
    duration: null,
    preload: false,
    countCooreRetry: 0,

    showPosition: 0,
    timer01: null
  }),

  destroyed() {
    document.body.removeEventListener("click", this.validationRestart);
  },

  computed: {
    ...mapState({
      time_received_turn: state => state.time_received_turn,
      turnUUID: state => state.turnUUID,
      version: state => state.version,
      environment: state => state.environment,
      company: state => state.company,
      company_queue: state => state.company_queue,
      sessionMaster: state => state.sessionMaster,
      this_user_data: state => state.client_data,
      turn: state => state.turn,
      tramite_info: state => state.tramite_info,
      env: state => state.env,
      turnCoorelation: state => state.turnCoorelation,
      client: state => state.autoservice.client,
      containerImageDocs: state =>
        state.virtualrow_atril_video.containerImageDocs,
      showProgressBar: state => state.virtualrow_atril_video.showProgressBar,
      timer: state => state.virtualrow_atril_video.timer,
      showProgressBarAun: state =>
        state.virtualrow_atril_video.showProgressBarAun,
      timerAun: state => state.virtualrow_atril_video.timerAun,

      username() {
        if (this.client)
          return `${this.client.Nombres} ${this.client.PrimerApellido} ${this
            .client.SegundoApellido || ""} `;
        else return "";
      },

      turn_from_code: state => state.autoservice.turn_from_code
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    debMediaServer() {
      return this.env.VUE_APP_DEBMEDIA_SERVER;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    },

    iOS() {
      return !!this.isiOS;
    },

    formalityName() {
      if (this.turn && this.company_queue) {
        let formalityData = this.company_queue.filter(
          t => t.id === _get(this.turn, "jsonDetails.queue.id")
        );
        return _get(formalityData, "[0].name");
      }
      return "";
    },

    companyName() {
      return _get(this.company, "name");
    },

    actualTurn() {
      return _get(this.turn, "jsonDetails.actualTurn", "canceled");
    },

    assingTurn() {
      if (this.turn && this.turn.status) {
        if (this.turn.status == "FINALIZED") return "";
      }
      return _get(this.turn, "jsonDetails.turn");
    },

    turnId() {
      return _get(this.turn, "code");
    },
    turnDetails() {
      return this.turnsInFrontCache;
    },
    turnsInFront() {
      return _get(this.turn, "jsonDetails.estimatorResult.turnsInFront");
    },
    turnsInFrontBSIX() {
      return _get(this.turn, "jsonDetails.estimatorResult.turnsInFrontBSIX");
    },
    waitingTime() {
      return _get(this.turn, "jsonDetails.estimatorResult.waitingTime");
    },
    status() {
      return _get(this.turn, "status");
    },
    userPhone() {
      if (
        _get(this.this_user_data, "phone.length") &&
        this.this_user_data.phone.length > 4
      ) {
        return (
          "*".repeat(this.this_user_data.phone.length - 4) +
          this.this_user_data.phone.slice(-4)
        );
      } else {
        return "";
      }
    },
    waiting_time() {
      if (this.time_received_turn === null) return "60 minutos";
      var now = moment();
      var turn_time = moment.unix(this.time_received_turn);
      var mins_elapsed = now.diff(turn_time, "minute");
      var num_min = Math.ceil(
        this.turnsInFrontCache // Previous: jsonDetails.averageWaitingTime
      );
      var time_to_attend = num_min - mins_elapsed;
      if ((this.timeAverageDirect ? num_min : time_to_attend) > 1) {
        return (
          (this.timeAverageDirect ? num_min : time_to_attend).toString() +
          " minutos"
        );
      } else {
        return "1 minuto";
      }
    },

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}${
        splited[1] ? "@" + asterisker(splited[1], 0, splited[1].length - 2) : ""
      }`;
    },

    // meet

    meetingId() {
      return this.turnCoorelation?.code_title;
    },

    dateWithoutHour() {
      return moment(this.codeInfo.startAt).format("YYYY/MM/DD");
    },

    hour() {
      return moment(this.codeInfo.startAt).format("hh:mm a");
    },

    checkInTolerance() {
      let tolerance;
      try {
        tolerance = JSON.parse(this.codeInfo.schedule.checkInTolerance);
      } catch (error) {
        tolerance = {};
      }
      return tolerance;
    }
  },

  async created() {
    let code = this.$route.query.code;
    let type = this.$route.query.type;

    // Check code and type
    if (code) {
      // 1. appointments 2. turns
      if (type == 1) {
        // Request to appointment by code
        axios
          .get(
            `${this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER}api/v2/appointments/${code}`,
            {
              headers: {
                "x-api-token": this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY
              }
            }
          )
          .then(async res => {
            let info = res.data;

            this.codeInfo = info;
            this.codeInfo.reason = JSON.parse(info.reason);

            // check appointment status
            if (info.status != "CONFIRMED") {
              switch (info.status) {
                case "CANCELED_INTERNALLY":
                case "CANCELED":
                  this.showCanceled = true;
                  this.showExpiredLink = false;
                  this.showExceededTime = false;
                  break;
                case "ABSENT":
                  this.showCanceled = false;
                  this.showExpiredLink = false;
                  this.showExceededTime = true;
                  break;
                case "CHECKED_IN":
                  this.showCanceled = false;
                  this.showExpiredLink = true;
                  this.showExceededTime = false;
                  break;
                default:
                  break;
              }
              this.showMeet = false;
              return (this.showExpired = true);
            }

            // return this.makeCodeToUrl();

            // Check time for appointment
            this.duration = moment.duration(
              moment(info.startAt).diff(moment())
            );
            var minutes = this.duration.asMinutes();

            // 5 minutes left for the appointment
            if (minutes > this.checkInTolerance.before) {
              this.showMeet = false;
              this.shwoEnded = false;
              this.showExpired = false;
              this.showExceededTime = false;
              let intervalM = setInterval(() => {
                this.duration = moment.duration(
                  moment(info.startAt).diff(moment())
                );
                var minutes = this.duration.asMinutes();
                // 5 minutes left for the appointment
                if (minutes <= this.checkInTolerance.before) {
                  clearInterval(intervalM);
                  // fetch turn
                  this.makeCodeToUrl();
                }
              }, 1000);
              return (this.showNotHourToTurn = true);
            } else {
              // 10 minutes exceeded show error
              let exceededTime = parseInt(minutes) * -1;
              if (exceededTime > this.checkInTolerance.after) {
                this.showMeet = false;
                this.showExceededTime = true;
                return (this.showExpired = true);
              } else {
                // fetch turn
                this.makeCodeToUrl();
              }
            }
          })
          .catch(err => {
            console.log(err);

            this.$router.push({
              name: "Decoder",
              query: { err: err, branchid: this.$route.query.branchid }
            });
          });

        // Code to turn
      } else {
        this.showMeet = false;
        this.preload = true;

        // Fetch turn with user info
        await this.fetchTurnData(code);

        // get cuuid from extrafields for coorrelation
        let UUID = this.turn_from_code[0]?.extra_fields.match(
          /(turn_assigner[/A-Z])\w+/g,
          "\\$&"
        );
        UUID = UUID[0]?.split("/");
        if (UUID.length > 0) {
          await this.setTurnUUID(UUID[1]);
          await this.getTurnInfo(code);
          if (this.turn) this.checkStatus(this.status);
        } else {
          console.error("In turnToMeetingAction", "Uuid no exist");
          this.errorCoorelationRequest = true; // TODO: How show this error? How to recover state?
        }

        this.preload = false;
      }
    }
  },

  mounted() {
    if (this.$route.query.code) return;

    setTimeout(async () => {
      const validation =
        !this.showVideosTikTok &&
        !this.showNotHourToTurn &&
        !this.showExpired &&
        !this.shwoEnded &&
        !this.showExpiredLink &&
        !this.showCanceled &&
        !this.initMeet;

      if (validation) {
        this.timer20 = this.recoursiveFunction(validation);
        document.body.addEventListener("click", this.validationRestart);
      }

      if (this.actualTurn == "canceled") this.cancelTurnHandler();

      if (window.location.href.indexOf("fv_atril") == -1) {
        this.checkPermissions();
      }

      // show modal turn
      if (this.assingTurn) this.$bvModal.show("new-turn-modal");

      this.startup();

      let font = _get(this.this_user_data, "fu");
      font = !font || font == "" ? "ad" : font;
      // tracking
      let trackingData = {
        uuid: window.person.id,
        accion: "customer-turno-asignado",
        debmedia_turn_code: this.turnId,
        url_origen: window.location.href,
        origen: font,
        mensajes: ""
      };

      this.sendTracking(trackingData);

      try {
        var hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") {
          hidden = "hidden";
          visibilityChange = "visibilitychange";
        } else if (typeof document.mozHidden !== "undefined") {
          hidden = "mozHidden";
          visibilityChange = "mozvisibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
          hidden = "msHidden";
          visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
          hidden = "webkitHidden";
          visibilityChange = "webkitvisibilitychange";
        } else {
          console.error("Page Visibility API not supported.");
        }

        document.addEventListener(
          visibilityChange,
          () => {
            // tracking
            let trackingData = {
              uuid: window.person.id,
              accion: document[hidden]
                ? "customer-visibilidad-oculta"
                : "customer-visibilidad-visible",
              debmedia_turn_code: this.turnId,
              url_origen: window.location.href,
              origen: font,
              mensajes: ""
            };

            this.sendTracking(trackingData);
          },
          false
        );
      } catch (error) {
        // tracking
        let trackingData = {
          uuid: window.person.id,
          accion: "customer-visibilidad-fail",
          debmedia_turn_code: this.turnId,
          url_origen: window.location.href,
          origen: font,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }
    }, 1000);
  },

  watch: {
    shwoEnded(val) {
      if (val) {
        this.timer01 = setTimeout(async () => {
          await this.endMeet();
          this.goToView({ view: "Home" });
          this.$router.push({path: "/fv_atril_video", query: this.$route.query});
          clearTimeout(this.timer01);
        }, 8000);
      }
    },
    status(val) {
      this.checkStatus(val);

      // tracking
      if (val) {
        setTimeout(() => {
          let trackingData = {
            uuid: window.person.id,
            accion: "customer-cambio-status: " + val,
            debmedia_turn_code: this.turnId,
            url_origen: window.location.href,
            origen: _get(this.this_user_data, "fu") || "ad",
            mensajes: ""
          };

          this.sendTracking(trackingData);
        }, 1000);
      }
    },
    turnsInFront(val) {
      if (val) this.turnsInFrontCache = val;
    },
    waitingTime(val) {
      if (val) this.waitingTimeCache = val;
    },
    async actualTurn(val) {
      if (!this.audioNotification && val != "canceled") {
        document.getElementById("advice").play();
      }
      if (val == "canceled") this.cancelTurnHandler();
    },
    audioNotification(val) {
      if (val) {
        document.getElementById("advice").currentTime = 0;
        document.getElementById("advice").play();
        // setTimeout(() => {
        //   document.getElementById("advice").currentTime = 0;
        //   document.getElementById("advice").play();
        // }, 10000);
      }
    },
    turn(val) {
      if (!val) this.cancelTurnHandler();
    },

    initMeet(val) {
      if (val) {
        clearTimeout(this.timer20);
        this.timer20 = null;
      }
    }
  },

  beforeDestroy() {
    if (this.lastTimerSound) clearTimeout(this.lastTimerSound);
    if (this.timerSound) clearTimeout(this.timerSound);
    if (this.timer01) clearTimeout(this.timer01);
  },

  methods: {
    ...mapActions({
      cancelTurn: "cancelTurn",
      getTurn: "getTurn",
      getTurnCoorelation: "getTurnCoorelation",
      putLog: "log/put",
      sendTracking: "virtualrow/handleTracking",
      goToView: "virtualrow_atril_video/goToView",
      updateB64Final: "autoservice/updateB64Final",
      fetchTurnData: "autoservice/fetchTurnData",
      getTurnInfo: "getTurnInfo",
      setTurnUUID: "setTurnUUID",
      endMeetChime: "meeting/endMeet",
    }),

    audioNotifyClick() {
      // console.log("Notificacion state", this.audioNotification);
    },

    goHomeReal() {
      this.goToView({ view: "Home" });
      if (this.$router.currentRoute.path != "/fv_atril_video")
        this.$router.push({path: "/fv_atril_video", query: this.$route.query});
    },

    validationRestart() {
      if (this.timer20) {
        clearTimeout(this.timer20);
        this.timer20 = null;
      }

      this.timer20 = setTimeout(() => {
        this.showUltimateModal2 = true;
        clearTimeout(this.timer20);
        this.timer20 = null;
      }, 120000);
    },

    restartTimerWaiting() {
      this.showUltimateModal2 = false;
      this.validationRestart();
    },

    onShowAunSiguesAlli() {
      this.showUltimateModal2 = true;
    },

    async establishCorrelation() {
      await setCorrelation({
        kuidId: this.turnUUID,
        turnId: kuid(),
        session: this.sessionMaster,
        turn_code: this.turn?.code
      });
    },

    soundEmmited() {
      if (this.timerSound) clearTimeout(this.timerSound);
      this.timerSound = null;
      // this.lastTimerSound = setTimeout(() => {
      // if (this.status !== "ANNOUNCED") this.cancelTurnHandler();
      // else this.turnToMeetingAction();
      this.turnToMeetingAction();
      // }, 1000);
    },

    async checkStatus(newStatus) {
      switch (newStatus) {
        case "ANNOUNCED":
          this.showVideosTikTok = false;
          if (this.audioNotification) {
            if (document.getElementById("attended")) {
              try {
                // this.timerSound = setTimeout(() => {

                // }, 2000);
                try {
                  if (
                    document.getElementById("attended") &&
                    document.getElementById("attended").currentTime > 0
                  )
                    return;
                } catch (error) {
                  this.$rollbar.warning("Invalid turn audio state", error);
                }
                this.soundEmmited();
                // await document.getElementById("attended").play();
                document.getElementById("attended").play();
              } catch (error) {
                this.$rollbar.info("Sound of meeting cant play");
                // if (this.status !== "ANNOUNCED") this.cancelTurnHandler();
                // else {
                // wait for user
                // this.$bvModal.show('confirm-modal')
                this.$bvModal.hide("new-turn-modal");
                // await new Promise(resolve => this.resolveconfirm = resolve);
                // this.$bvModal.hide('confirm-modal')
                this.turnToMeetingAction();
                // }
              }
            }
          } else {
            // if (this.status !== "ANNOUNCED") this.cancelTurnHandler();
            // else {
            // wait for user
            // this.$bvModal.show('confirm-modal')
            this.$bvModal.hide("new-turn-modal");
            // await new Promise(resolve => this.resolveconfirm = resolve);
            // this.$bvModal.hide('confirm-modal')
            this.turnToMeetingAction();
            // }
          }
          break;
        case "WAITING_TO_BE_CALLED":
          this.showMeet = false;
          break;
        case "FINALIZED":
          this.showVideosTikTok = false;
          this.showMeet = false;
          this.showExpired = false;
          this.initMeet = false;
          this.showExceededTime = false;
          this.shwoEnded = true;
          this.cancelTurnHandler();
          localStorage.clear();
          this.$router.push({path: "/finished_fv_atril", query: this.$route.query});
          break;
        case "REVOKED":
          this.showVideosTikTok = false;
          this.showMeet = false;
          this.showExpired = true;
          this.initMeet = false;
          this.showExceededTime = false;
          this.shwoEnded = false;
          this.cancelTurnHandler();
          localStorage.clear();
          this.$router.push({path: "/finished_fv_atril", query: this.$route.query});
          break;
        case "CANCELED_BY_USER":
          this.showVideosTikTok = false;
          localStorage.clear();
          this.$router.push({path: "/finished_fv_atril", query: this.$route.query});
          // case "ABSENT":
          this.cancelTurnHandler();
          break;

        default:
          break;
      }
      return newStatus;
    },

    goHome() {
      this.goToView({ view: "Home" });
      setTimeout(() => {
        this.$router.push({path: "/fv_atril_video/", query: this.$route.query});
      }, 1000);
    },

    async turnToMeetingAction() {
      try {
        // await this.getTurnCoorelation(this.turnUUID);
        if (!this.audioNotification && this.actualTurn != "canceled")
          document.getElementById("advice").play();
        // LS.setItem("audioNotification", this.audioNotification);
        // this.$router.push(`/meeting/${code_title}`);
        this.initMeet = true;
        this.showMeet = true;
        this.showAudioNotification = false;

        setTimeout(() => {
          let trackingData = {
            uuid: window.person.id,
            accion: "customer-obtencion-id-videollamada",
            debmedia_turn_code: this.turnId,
            url_origen: window.location.href,
            origen: _get(this.this_user_data, "fu") || "ad",
            mensajes: this.meetingId
          };

          this.sendTracking(trackingData);
        }, 1000);
      } catch (error) {
        console.error("In turnToMeetingAction", error);
        this.errorCoorelationRequest = true; // TODO: How show this error? How to recover state?
        this.putLog({
          name:
            "Turno. Error restrictivo. No fue posible establecer la coorelación o el turno. En turnToMeetingAction",
          checked: false,
          message: error
        });

        setTimeout(() => {
          this.countCooreRetry++;
          if (this.countCooreRetry < 3) {
            this.turnToMeetingAction();
          }
        }, 1000);
      }
    },

    async startup() {
      try {
        this.putLog({
          name: "Turno. Se estableció la coorelación."
        });
        await this.getTurn();
        this.putLog({
          name: "Turno. Se creó el turno."
        });
        if (this.turn) this.checkStatus(this.status);
        if (!this.turnId) this.cancelTurnHandler();
        if (this.turnsInFront) this.turnsInFrontCache = this.turnsInFront;
        if (this.waitingTime) this.waitingTimeCache = this.waitingTime;
      } catch (error) {
        this.showMeet = false;
        console.log("EERROR");
        console.log(error);
        this.errorStartup = true;
        this.putLog({
          name:
            "Turno. Error restrictivo. No fue posible establecer la coorelación o el turno.",
          checked: false,
          message: error
        });
        if (this.turn) this.checkStatus(this.status);
      }
    },
    async cancelTurnHandler() {
      try {
        await this.cancelTurn();
        await this.endMeetChime();
        this.$bvModal.hide("cancel-modal");
        this.goToView({ view: "Home" });
      } catch (error) {
        console.error("Cant cancel turn in component", error);
      }
      // this.$router.push({ name: "Canceled" });
      this.showVideosTikTok = false;
      this.shwoEnded = true;
      this.showMeet = false;
    },

    // Meet

    async endMeet() {
      await this.cancelTurn({ local: true });
      await this.endMeetChime();
      this.goToView({ view: "Home" });
      this.shwoEnded = true;
      this.initMeet = false;
    },

    checkPermissions() {
      let constraints = { audio: true, video: true };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          console.log("permissions success");
          console.log(stream);
        })
        .catch(err => {
          if (err.name == "NotAllowedError") {
            if (err.message == "Permission denied") {
              return;
            }
            this.checkPermissions();
          }
        });
    },

    attendees(loading) {
      this.loading = loading;
      if (!loading) {
        // Check connection
        this.checkConnection();
      }
    },

    recoursiveFunction(validation) {
      return setTimeout(() => {
        if (validation) {
          this.showUltimateModal2 = true;
          clearTimeout(this.timer20);
          this.timer20 = null;
        } else {
          this.recoursiveFunction(validation);
        }
      }, 120000);
    },

    checkConnection() {
      let time;
      setTimeout(() => {
        console.log("Check connection...");
        console.log("loading: " + this.loading);
        if (this.loading) {
          console.log("Reinstanciando...");
          this.initMeet = false;
          this.showMeet = false;
          time = setTimeout(() => {
            console.log("Reinstanció la videollamada");
            this.initMeet = true;
            this.showMeet = true;
            this.checkConnection();
            this.loading = false;
          }, 2000);
        } else {
          this.initMeet = true;
          this.showMeet = true;
          clearTimeout(time);
        }
      }, 10000);
    },

    async onCancelTurn() {
      await this.endMeet();
      this.cancelTurnHandler();
      this.$router.push({ path: "/fv_atril_video", query: this.$route.query });
    },

    continueTurn() {
      this.restartTimerWaiting();
      document.getElementById("advice").play();
      this.$bvModal.hide("new-turn-modal");
    },

    async makeCodeToUrl() {
      // Make JSON
      let to = await getOneUseToken();
      // let ma = "cliente";
      let su = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH);
      let tr = Number(
        this.codeInfo.reason?.tramite ||
          this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE
      );
      let na = this.codeInfo.customer.firstName;
      let ap = this.codeInfo.customer?.lastName || "";
      let la = "";
      let ma = this.codeInfo.reason?.marcacion;
      let ti = this.codeInfo.customer.cuit;
      let nu = this.codeInfo.customer.dni;
      let ce = this.codeInfo.customer.phone;
      let so = this.codeInfo.reason?.so;
      let fu = this.codeInfo.reason?.fu + "_appointment";
      let br = this.codeInfo.reason?.br;
      let os = this.codeInfo.reason?.os;
      let ac = this.codeInfo.code; // appointment code

      let jsonFinal = {
        to,
        tr,
        su,
        fu,
        ma,
        na,
        ap,
        la,
        ti,
        nu,
        ce,
        so,
        br,
        os,
        ac
      };
      this.updateB64Final(jsonFinal);

      let jsonB64 = JSON.stringify({
        ...jsonFinal,
        sv: this.service,
        to
      });

      this.$router.push({
        name: "Decoder",
        query: { pr: utf8_to_b64(jsonB64) }
      });
    }
  }
};
</script>
<style>
.mediaServer {
  position: absolute;
  width: 100%;
  bottom: 10px;
  font-size: 0.6rem;
}
.task_name {
  font-size: 1.5em;
  padding-bottom: 3vh;
  padding-top: 50px;
  font-family: Helvetica-bold;
}

.main_box {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  text-align: center;
  font-size: 1em;
  /* padding-top: 35.75px; */
}

.main_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info_box {
  position: relative;
  z-index: 0;
  background-color: #00599d;
  color: white;
  border-radius: 7px;
  height: 256px;
}

.personel_logo {
  width: 50%;
  max-width: 275px;
  height: auto;
}

.clock_logo {
  width: 45px;
  height: auto;
}

.header {
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.text-header-container {
  position: relative;
  height: 10vh;
  width: 50%;
  float: left;
}

.text-vetical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.2em;
  font-weight: 600;
}

.TurnPage {
  background-color: #f2f4f8;
  padding: 1.5rem;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 50px;
}

html {
  overflow: hidden;
}

.modal-body {
  padding: 50px !important;
}

.div_center_button {
  position: absolute;
  bottom: 3vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px 20px;
}

.boxInfo {
  max-width: 347px;
  margin: auto;
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}

.Page {
  position: fixed;
  bottom: 105px;
  height: calc(50vh - 105px);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonTurnero {
  width: 100%;
  display: block;
  margin: auto;
  max-width: 317.25px;
}

.color-primary {
  color: #3290e0;
}

.cancelTurnDialog {
  color: var(--color-gray);
}

.form-data {
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}

.modal_highlight_text {
  color: #00599d;
  font-size: 1.1em;
  font-family: Helvetica-bold;
}

.modal_inside {
  color: #979797;
  font-family: Helvetica-medium;
}

.AssignmentText {
  font-size: 21px;
  line-height: 28px;
}

.vue-simple-progress-text {
  font-size: 28px !important;
}

.ImportantAsignment {
  color: var(--color-secondary);
}

.Header_container {
  max-width: 411px;
  margin: auto;
}

.YoutubeVideoFrame {
  border-radius: 15px;
  background-color: white;
  margin-top: 40px;
}

.font-larger {
  color: var(--color-secondary);
}

.Link {
  cursor: pointer;
  color: #3290e0;
  line-height: 20px;
  margin-top: 28.44px;
}

.AnotherLink {
  cursor: pointer;
  color: var(--color-gray);
  line-height: 20px;
  margin-top: 28.44px;
  text-decoration: underline;
}

.modal_button {
  width: 100%;
  font-size: 1.4em;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* font-family: Helvetica-medium; */
}

.max-width-box-form {
  max-width: 464px;
}

.font-lg-16 {
  font-size: 20.35px;
}

.ContainerMeetingOf {
  max-width: 411px;
  margin: auto;
}

@media only screen and (max-width: 528px) {
  .info_box {
    height: 300px;
  }
}

.div_center_button {
  position: relative;
  margin-top: 20px;
}

#video {
  width: 100%;
}

.rootAu {
  max-width: 825px;
  margin: auto;
}

#introduce___BV_modal_content_ {
  max-width: 640px !important;
  width: 640px !important;
}

.buttonText {
  font-size: 28px;
}

.Subtitle {
  font-size: 32px;
}

.Title {
  font-size: 38px;
  font-weight: bold;
  color: var(--color-primary);
}

.fontBack {
  font-size: 40px;
}

.TimerContainer {
  position: fixed;
  right: 238px;
  top: 48%;
  z-index: 13;
  transform: translateY(-50%);
  background: white;
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content {
  max-width: 600px;
  /* margin: 10px; */
  position: absolute;
  margin: auto;
  left: 50%;
  bottom: 105px;
  /* top: 50%; */
  transform: translateX(-50%);
}

#small-modal-dk___BV_modal_content_,
#new-turn-modal___BV_modal_content_,
#cancel-modal___BV_modal_content_ {
  max-width: 680px;
  /* margin: 10px; */
  width: 700px;
  position: absolute;
  margin: auto;
  bottom: 105px;
  left: 50%;
  transform: translateX(-50%);
}

#small-modal-dk___BV_modal_content_,
#new-turn-modal___BV_modal_content_,
#cancel-modal___BV_modal_content_ {
  bottom: auto;
  top: 72.5%;
  transform: translate(-50%, -50%);
}

#new-turn-modal___BV_modal_content_ {
  top: 72.5% !important;
}

.modal-dialog {
  max-width: 600px;
}

.AuthMeds {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 65px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.AuthsBtn {
  margin: 100px auto;
  width: 561px;
  padding: 9px 3.5rem;
  line-height: 65px;
  font-weight: 600 !important;
}

button.AuthsBtnDanger {
  background-color: rgb(245, 28, 36);
  color: white;
  margin: 50px auto;
  width: 390px;
  border-radius: 100px;
}

#Finalize-call___BV_modal_content_ {
  top: 52%;
  height: fit-content;
}

.timer-container {
  position: absolute;
  right: 30px;
  top: 30px;
}
</style>
