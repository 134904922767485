<template lang="pug">
.TikTokVideosContainer
  button(@click="goBack" type="button").button-without-styles.pb-3
    svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
      path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
  .TikTokVideos
    video(
      v-for="(video, index) in videos"
      :key="index"
      :src="video"
      loop
      controls
      @volumechange="onVideoAction"
    ).VideoColmedica
    .boxAttendee(
      v-if="assignTurn"
      @drop="handlerDrop"
      @dragover="handlerDragover"
    )
      h4.text-center.AssignmentTextAttendee Turno asignado:
      h3.text-center.font-weight-bold.ImportantAsignment {{ assignTurn }}
      //- h5.mt-1.text-center.mb-1.ActualAttendee Próximo turno:
      //- h5.text-middle.font-larger {{ actualTurn }}
      h5.mt-1.text-center.mb-1.ActualAttendee Posición en la fila:
      h5.text-middle.font-larger {{ typeof turnsInFront === "number" ? turnsInFront + 1 : "1" }}
    .containerDescTikTok(v-if="showSignal")
      img.containerDescTikTok-img(src="@/assets/handIcon.svg")
      p.containerDescTikTok-text.px-4.pt-3 Desliza hacia arriba para ver más
  a(
    v-b-modal.cancel-modal
    v-if="assignTurn"
    variant="bluecolmedica"
    size="sm"
    type="button"
    style="color: crimson; width: fit-content;"
  ).color-danger.buttonText.mx-auto.mt-5.d-block Cancelar Solicitud
</template>

<script>
import interact from "interactjs";

export default {
  name: "TikTokVideos",

  data: () => ({
    showSignal: true,
    videos: [
      "https://bsix.assets.gobrilliant.com/colmedica/oficinavirtual-videos/tiktokparanodeportistass_1.mp4",
      "https://bsix.assets.gobrilliant.com/colmedica/oficinavirtual-videos/tiktokritmodesuenoo_2.mp4",
      "https://bsix.assets.gobrilliant.com/colmedica/oficinavirtual-videos/tiktokbruxissmo_3.mp4",
      "https://bsix.assets.gobrilliant.com/colmedica/oficinavirtual-videos/tiktoksindromecorazonroto_4.mp4",
      "https://bsix.assets.gobrilliant.com/colmedica/oficinavirtual-videos/tiktoksingluten_5.mp4"
    ]
  }),

  props: ["actualTurn", "assignTurn", "goBack", "turnsInFront"],

  mounted() {
    const position = { x: 0, y: 0 };
    const boxAttendee = interact(".boxAttendee");

    boxAttendee.draggable({
      listeners: {
        move(event) {
          position.x += event.dx;
          position.y += event.dy;

          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
        }
      }
    });

    const timer = setTimeout(() => {
      this.showSignal = false;

      clearTimeout(timer);
    }, 3500);
    const options = {};

    this.observer = new IntersectionObserver(this.handlerObserver, options);

    [...document.querySelectorAll(".VideoColmedica")].map($videoColmedica => {
      this.observer.observe($videoColmedica);
    });
  },

  methods: {
    onVideoAction(element) {
      [...document.querySelectorAll(".VideoColmedica")].map($videoColmedica => {
        if (element.target.muted) {
          $videoColmedica.muted = true;
        } else {
          $videoColmedica.muted = false;
        }
      });
    },
    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          entry.target.pause();
          entry.target.currentTime = 0;
        } else {
          [...document.querySelectorAll(".VideoColmedica")].map(
            $videoColmedica => {
              $videoColmedica.pause();
            }
          );
          entry.target.play();
        }
      });
    },
    handlerDrop(event) {
      console.log("Fichero(s) arrastrados", event);
    },
    handlerDragover(ev) {
      console.log("File(s) in drop zone");

      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
    }
  }
};
</script>

<style lang="scss" scoped>
.TikTokVideos {
  width: 100%;
  height: 740px;
  position: relative;

  overflow: auto;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  background-color: white;

  // .absoluteHeader {
  //   position: fixed;
  //   top: 15px;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }

  .VideoColmedica {
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
  }

  .buttonTurnero2 {
    max-width: 136px;
    padding: 0.25rem !important;

    span {
      font-size: 16px;
    }
  }

  .boxAttendee {
    width: 100%;
    max-width: 202px;
    padding: 1rem;

    position: fixed;
    top: 50%;
    right: 32px;
    -ms-touch-action: none;
    touch-action: none;

    border-radius: 15px;
    box-shadow: 1px 0px 20px 0px #999999;
    background: rgba(244, 244, 244, 0.65);
    .ImportantAsignment {
      color: #009fe3;
    }
    .font-larger {
      margin: auto;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
    .AssignmentTextAttendee,
    .ImportantAsignment {
      font-size: 20px;
    }
    .ActualAttendee {
      font-size: 20px;
    }
  }

  .containerDescTikTok {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    filter: drop-shadow(0px 28px 36px rgba(0, 0, 0, 1));

    img.containerDescTikTok-img {
      max-width: 142px;
      width: 100%;
    }

    .containerDescTikTok-text {
      color: #f4f4f4;
      text-align: center;
    }
  }
}

.botton-actions {
  position: fixed;
  z-index: 10;
  top: 20px;
  left: 15px;
  margin-left: 0 !important;
}

.TikTokVideosContainer {
  position: relative;
}
</style>
