<template lang="pug">
.bg-alice(:style="whiteC && 'background-color: darkgray;'")
  .BottomTimer#bottomTimer(:style="whiteC && 'background-color: white;'")
</template>

<script>
export default {
  name: "BottomTimer",

  props: ["doThis", "$element", "isNotTimer", "whiteC", "limit"],

  data() {
    return {
      timer: null,
      counter: 0
    };
  },

  mounted() {
    this.startTimer();
    document
      .querySelector(this.$element || ".modal-content")
      .addEventListener("click", this.onClick);
  },

  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
    document
      .querySelector(this.$element || ".modal-content")
      ?.removeEventListener("click", this.onClick);
  },

  methods: {
    onClick() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
        this.counter = 0;
        if (document.getElementById("bottomTimer")) {
          document.getElementById(
            "bottomTimer"
          ).style.transform = `scaleX(${this.counter / (this.limit || 15)})`;
        }
      }
      this.startTimer();
    },

    startTimer() {
      this.timer = setInterval(() => {
        this.counter += 1;
        if (!document.getElementById("bottomTimer")) {
          clearInterval(this.timer);
          this.timer = null;
          return;
        }
        document.getElementById("bottomTimer").style.transform = `scaleX(${this
          .counter / (this.limit || 15)})`;
        if (!this.isNotTimer) document.body.click();
        if (this.counter >= (this.limit || 15)) {
          clearInterval(this.timer);
          this.doThis();
        }
      }, 1000);
    }
  }
};
</script>

<style scoped>
.BottomTimer {
  height: 10px;
  border-radius: 5px;
  transform: scaleX(0);
  background-color: #009fe3;
  transition: 0.25s all;
  will-change: transform;
  transform-origin: left;
  display: none;
}

.bg-alice {
  background-color: #cde3ec;
}
</style>
