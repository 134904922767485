<template lang="pug">
.container_meet(:style="{ height: UsableHeight, width: meetType === 'agent' ? '500px' : '100%', margin: meetType === 'agent' ? '0px auto' : '' }")
  .Overlay#OverlayDocument(style="display: none;")
  .ModalContent#AddDocument
    .position-relative.w-auto(style="height: 94%;")
      .position-relative.pt-4.d-flex.justify-content-between.pb-2
        button(@click="onDiscard" v-if="!modeCropper").button-without-styles.ml-4
          img(src="@/assets/kiosco46/cancel_white.svg" alt="cancel" @click="showModalScan = false").cancelIcon
        span
        //- button(@click="addDocument()").button-without-styles.mr-4
        //-   svg(width='22' height='16' viewbox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg')
        //-     path(d='M2 8L8 14L20 2' stroke='#0058A2' stroke-width='3.12' stroke-linecap='round' stroke-linejoin='round')
      .d-block.text-center.Background
        figure(v-if="holdIsImagen" :style="showImage ? '' : 'opacity:0' ").m-0.containerImage.mx-auto.block.pt-5.px-3
          img(
            :src="urlData"
            alt="imageUser"
            ref="fileImage"
            v-show="cropper"
          ).object-fit-cover.w-100.h-100.p-16.imageModal#generalImage.d-block
        .pt-4.mb-4.TextSection(v-if="!holdIsImagen") Sin vista previa del documento
        div(v-if="!holdIsImagen")
          b-button(v-if="!modeCropper" variant="bluecolmedica" block size="lg" type="button" @click="addDocument()" style="width: fit-content").text-center.AuthsBtn.my-4
            p.buttonText.AuthMeds.mb-0 Usar imagen
        .zone-button(v-if="holdIsImagen")
          .mx-5
            div(v-if="compressImagen === false")
              .p-2.d-flex.justify-content-around.w-100.mt-3.mb-0(v-if="!modeCropper || holdLoading")
                .circleCard.mr-3.d-flex.text-center
                  div(@click="() => onRotation(-90)")
                    img(src="@/assets/kiosco46/left.svg" alt="left").iconCrop
                  p.textCard.mt-1 Izquierda
                .circleCard.ml-2.d-flex.text-center
                  div(@click="() => onRotation(90)")
                    img(src="@/assets/kiosco46/right.svg" alt="right").iconCrop
                  p.textCard.mt-1 Derecha
                .circleCard.ml-4.d-flex.text-center
                  div(@click="activateCropper()")
                    img(src="@/assets/kiosco46/crop.svg" alt="crop").iconCrop
                  p.textCard.mt-1 Recortar
              b-button(v-if="!modeCropper" variant="bluecolmedica" block size="lg" type="button" @click="addDocument()" style="width: fit-content;").text-center.AuthsBtn.mt-4.mb-5
                p.buttonText.AuthMeds.mb-0 Usar imagen
              .p-2.d-flex.justify-content-around.w-100.mt-3.mb-1(v-else)
                .circleCard.mr-3.d-flex.text-center
                  div(@click="onCancel()")
                    img(src="@/assets/kiosco46/cancel_2.svg" alt="cancel_2").iconCrop
                  p.textCard.mt-1 Cancelar
                .circleCard.ml-2.d-flex.text-center
                  div(@click="onAccept()")
                    img(src="@/assets/kiosco46/accept.svg" alt="accept").iconCrop
                  p.textCard.mt-1 Aceptar
      .pb-4.mx-5(v-if="showAddDocument")
        BottomTimer(:doThis="() => onDiscard()" $element="#AddDocument" :isNotTimer="true" :whiteC="true")
  div(:style="attendees.length < 2 ? 'margin: 20px 0 0 0;' : ''").col-12.p-0.header.p-1.position-relative
    Header.d-block.h-100.mx-auto(@click="debugModeCounter" :src="Logo" v-openlog)
    div(class="version-position") {{ version }}
    transition(name="fade")
      .videoStopSuggestionMessage.text-danger(v-if="videoStopSuggestion") Por problemas de red, hemos desactivado tu cámara. 
    //- div(v-if="meetType === 'agent'")
    //-   h5(
    //-     v-if="Array.isArray(attendees) && attendees.length < 2"
    //-     style="margin-top: 9vh; text-align: center; color: #212529;"
    //-   ) Esperando Cliente...
    .Page(v-if="meetType != 'agent'")
      .MeetingPage(style="margin: 0;")
        //- h5(
        //-   v-if="turn.meet && turn.meet.assistants.length < 2"
        //-   style="margin-top: 9vh; text-align: center; color: #212529;"
        //- ) Esperando Asesor...

        .main_box(v-if="attendees.length < 2")
          .col-12(style="z-index: 1;" )
            h4.mt-4.color-colmedica-gray.buttonText Tu turno asignado es:
            //- h4.text-middle.color-colmedica-gray número:
            h3.buttonText.color-colmedica-light.font-weight-bold {{ assingTurn }}
            h5.mt-5.buttonText.color-colmedica-gray Estamos conectando tu videollamada

            //- p.color-colmedica En este momento presentamos congestión en nuestro canal, si ya enviaste foto de la orden médica y no vas a acceder inmediatamente al servicio, puedes cancelar la solicitud y te daremos respuesta al correo y te contactaremos si requerimos información adicional..
  .Page(v-show="attendees.length >= 2")
    .MeetingPage(@click="enableConfig++")
      .col-12.p-0.videozone(v-show="meetType == 'agent' || attendees.length >= 2")
        VideoCall(
          :audioId="audioId",
          :videosIds="videosIds"
          @CamChange="selectedDeviceInput('VideoInput')"
        )
      div(style="text-align: center; max-width: 940px; width: 100%; margin: auto; color: #6C6B74; margin-top: 5px;" v-show="meetType == 'agent' || attendees.length >= 2")
        .d-flex.justify-content-center.align-items-center
          div(:class="attendees.length >= 2 ? 'green-circle' : 'red-circle'")
          h5.px-3.pt-0.m-0.buttonText: span.d-block Asesor:
        h5.px-3.pt-0.buttonText: strong {{ helperAgent }}
      //- div(style="text-align: center; max-width: 940px; width: 100%; justify-content: center; margin: auto; color: #6C6B74; display: flex; align-items: center; margin-top: 5px;"
      //-   v-show="meetType == 'agent' || attendees.length >= 2"
      //-   )
      //-   div(:class="attendees.length >= 2 ? 'green-circle' : 'red-circle'")
      //-   small.buttonText {{ attendees.length >= 2 ? 'Activo ahora' : 'Inactivo ahora' }}
      .col-12.p-0.chatzone(v-show="meetType == 'agent' || attendees.length >= 2")
        //- v-show="allowChat"
        .chatWrapper(
          v-show="false"
        )
          div(class="containerMsg")
            div(class="position-relative fullChat")
              .dateTittle
                //- span {{ nameDataShowable }}
                span {{ timeDay }}
              .messagesZone.p-2(id="full_messages")
                .d-flex.justify-content-center.mb-2(
                  v-for="(msg, i) in messages" :key="`message_${i}`"
                  @click="download(i)"
                  :id="`message_${i}`"
                  :class="{ 'justify-content-start': !msg.isSelf, 'justify-content-end': msg.isSelf, 'uniqueMessage': msg.text.includes('DKMessageUnique1112233445566778899101000'), 'marginNegative': msg.text.includes('DKLocoarsaTUUIO123456') }"
                  v-if="msg.text != '33Silver49Finalizado' && !msg.text.includes('hidden-attach:') "
                )
                  .msg_cotainer(style="max-width: 80%; min-width: 162px;")
                    div(:class="{ 'triangulo': msg.isSelf, 'triangulo_noself': !msg.isSelf }" v-if="!msg.text.includes('DKMessageUnique1112233445566778899101000')")
                    div(class="msg_owner" :class="{ 'msg_owner_client': msg.isSelf, 'msg_owner_agent': !msg.isSelf }")
                      //- p.pharragraph_message {{ msg.attendeeName }}
                      p.pharragraph_message {{ msg.isSelf ? "Tu" : nameParticipant(msg.text, msg.attendeeName) }}
                      //- span(style ="margin-left: 5px;" :class="{ 'msg_time': !msg.isSelf, 'msg_time_send': msg.isSelf }") {{ timeFormat(msg.timestamp) }}
                    div(:class="{'loading_msg-dk': msg.text.includes('loading123456789987456123'), 'msg_unique_text': msg.text.includes('DKMessageUnique1112233445566778899101000'), 'msg_text': !msg.text.includes('DKMessageUnique1112233445566778899101000')}" :style="{ backgroundColor:  msg.isSelf ? '#212624' : '#54678F', color: '#E9EBF2' }")
                      p(style="margin: 0;" v-html="msg.text.split('name:')[0]")
                      p(v-if="!msg.text.includes('DKMessageUnique1112233445566778899101000')" style ="margin: 0; margin-left: 5px;" :class="{ 'msg_time': !msg.isSelf, 'msg_time_send': msg.isSelf }") {{ timeFormat(msg.timestamp) }}
                .d-flex(
                  v-if="loadingFile"
                  class="justify-content-end"
                )
                  .msg_cotainer(style="max-width: 90%; min-width: 162px;")
                    div.triangulo.disabled-triangle
                    div.msg_text(style="background-color: rgb(33 38 36 / 60%); color: #d8dee2;")
                      div.position-relative.DKLocoarsaTUUIO123456
                        div(style="display: flex; justify-content: space-between;")
                          //- p Enviando archivo ...
                          //- img(src="./loading-buffering.gif" alt="loading" style="max-width: 19px; width: 100%; height: 19px;")
                          progress-bar( size="medium" :val="valueProgress" style="width: 100%; height: 30px;" :text="`Enviando archivo ... ${valueProgress}%`" :text-fg-color="'white'")
                      a.folderIcon-DK.text-truncate.pt-0.pb-0(href="#" target="_blank" @click="(e) => e.preventDefault()" style="color: #d8dee2; height: 30px;")
                        svg(style="width:24px;height:24px" viewBox="0 0 24 24")
                          path(fill="white" d="M20,6A2,2 0 0,1 22,8V18A2,2 0 0,1 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10L12,6H20M19.25,13H16V9H14V13H10.75L15,17.25")
                        span(style="margin: 0; height: 30px;").fileChatOK {{ fileNameLoading }}
                        span .{{ fileExtension }}
                .typing(v-if="typing")
                  .triangulo-chat
                  .lds-ellipsis
                    .into-ellipsis
                    .into-ellipsis
                    .into-ellipsis
                    .into-ellipsis
                .typing-2(v-if="typing")
                  .triangulo_noself-chat
                  .lds-ellipsis
                    .into-ellipsis
                    .into-ellipsis
                    .into-ellipsis
                    .into-ellipsis
              .input-group.p-2(v-show="allowChat")
                input(
                  type="file"
                  accept="*/*"
                  style="display: none"
                  ref="file"
                  @change="fileHandlerGeneral"
                )
                input(
                  type="file"
                  accept="image/*"
                  style="display: none"
                  ref="image"
                  @change="fileHandlerImage"
                )
                .input-group-append
                  span.input-group-text.attach_btn.py-0.px-2.d-flex.justify-content-center.align-items-center.attachFileToChat(
                    @click="simulateAttach"
                    :disabled="loadingFile"
                    :style="{ borderRadius: '5px 0px 0px 5px' }"
                  )
                    Attach(size="25" class="d-block rotatedk")
                  span.input-group-text.attach_btn.py-0.px-2.d-flex.justify-content-center.align-items-center.attachImageToChat(
                    @click="simulateImage"
                    :disabled="loadingFile"
                  )
                    Camera(size="25" class="d-block")
                input.form-control.type_msg(placeholder="Ingrese su mensaje..." v-model="message" @change="handleChangeMessage" @keyup.enter="messageHandler")
                .input-group-append(@click="messageHandler")
                  span.input-group-text.send_btn.py-0.px-2.d-flex.justify-content-center.align-items-center
                    SendOutline(size="20" class="d-block" v-if="!showSendIcon")
                    Send(size="20" class="d-block" v-if="showSendIcon")
        .p-5
        .bottomzone
          .d-flex.justify-content-center.align-items-center
            .row(style="width: 535px;")
              .col.text-center(
                style="height: 50px;"
                v-if="meetType === 'agent'"
                @click="toogleSharingEnabled"
                :class="{ 'desktopSharingArea isShared': isSharedVideo, 'desktopSharingArea isNotShared': !isSharedVideo }"
              )
                div(:style="{ color: isSharedVideo ? '#20a464' : '#F60303', position: 'relative', height: '15px', width: '100%' }")
                  MonitorShare(class="size-buttons" size="100%").MonitorClass
              .col.text-center(
                class="listStatusDialogShower"
                style="height: 50px;"
                @click="$bvModal.show('List-status')" v-if="debugButton"
              )
                div(style="color: #F60303; position: relative; height: 15px; width: 100%")
                  ListStatus(class="size-buttons" size="100%").MonitorClass
              .col.text-center(
                :class="{ 'speakerArea isShowed': speakerEnabled, 'speakerArea isHiden': !speakerEnabled }"
                style="height: 30px;"
                @click="toogleSpeakerEnabled()" v-if="false"
              )
                span
                  img(:src="speakerEnabled ? VolumeHigh : VolumeHighOff" class="size-buttons" size="100%")
              .col.text-center(
                :class="{ 'micArea isShowed': audioEnabled, 'micArea isHiden': !audioEnabled }"
                style="height: 30px;"
                @click="toogleAudioEnabled()"
              )
                div(:class="audioEnabled ? '' : 'doRedCam'" :style="!audioEnabled && 'margin-top: 5px'")
                  span
                    img(:src="audioEnabled ? Mic : MicOff" class="size-buttons size-buttonMic" size="100%")
              .col.text-center(
                :class="{ 'camArea isShowed': camEnabled, 'camArea isHiden': !camEnabled }"
                style="height: 30px;"
                @click="startCamFromBasis"
              )
                div(:class="camEnabled ? '' : 'doRedCam'" :style="!camEnabled && 'margin-top: 5px'")
                  span
                    img(:src="camEnabled ? VideoCam : VideoCamOff" class="size-buttons" size="100%" style="margin-top: 12px;")
              .col.text-center(
                class="cancelMeetDialog"
                style="height: 30px;"
                @click="$bvModal.show('Finalize-call')"
              )
                span(style="color: #F60303").pt-1
                  img(:src="PhoneOff" class="size-buttons" style="width: 60px; margin-top: 12px;")
                  .p-2
              .col.text-center(
                v-if="enableConfig>2"
                class="config"
                style="height: 30px;"
                @click="onShowConfig"
              )
                .PopupConfig.p-3(v-if="showPopupConfig" @click="(e) => e.stopPropagation()")
                  p.Subtitle.m-0 Menú de configuración:
                  b-button.my-4(variant="bluecolmedica" block size="lg" type="button" @click="selectedDeviceInput('VideoInput')").text-center.AuthsBtn
                    p.buttonText.m-0 Cámaras
                  b-button.my-4(variant="bluecolmedica" block size="lg" type="button" @click="selectedDeviceInput('AudioInput')").text-center.AuthsBtn
                    p.buttonText.m-0 Micrófono
                  b-button.my-4(variant="bluecolmedica" block size="lg" type="button" @click="selectedDeviceInput('AudioOutput')").text-center.AuthsBtn
                    p.buttonText.m-0 Parlantes
                span(style="color: gray").pt-1
                  img(:src="Settings" class="size-buttons" style="width: 60px; height: 60px; margin-top: -7px;")
                  .p-2
          //- .containerIcons(style="background-color: #007bff")
          //-   img(src="@/assets/kiosco46/plus.svg" alt="plus").plus
          //- p.buttonText.mt-1 Adjuntar documento
        .mx-auto.mt-5(style="width: fit-content;" @click="() => timer5 ? null : onShowModal()").text-center.container2.pt-5.pb-4
          b-button(variant="bluecolmedica" blockQ size="lg" type="button" style="width: 500px; display: flex; align-items: center; justify-content: center;").text-center.AuthsBtn.my-4
            img(src="@/assets/kiosco46/plus.svg" alt="plus").plus
            p.buttonText.AuthMeds.mb-0.ml-3 Adjuntar más documentos
        .py-5.justify-content-center.d-flex(v-if="showMessageProgress")
          .msg_cotainer(style="max-width: 90%; min-width: 162px;")
            div.triangulo.disabled-triangle
            div.msg_text(style="background-color: rgb(33 38 36 / 60%); color: #d8dee2;" v-if="!loadedImage")
              div.position-relative.DKLocoarsaTUUIO123456
                div(style="display: flex; justify-content: space-between;")
                  //- p Enviando archivo ...
                  //- img(src="./loading-buffering.gif" alt="loading" style="max-width: 19px; width: 100%; height: 19px;")
                  progress-bar(size="medium" :val="valueProgress" style="width: 100%; height: 15px; font-size: 28px;" :text="`Enviando archivo ... ${valueProgress}%`" :text-fg-color="'white'")
              a.pt-0.pb-0(href="#" target="_blank" @click="(e) => e.preventDefault()" style="color: #d8dee2; height: 40px; opacity: 0;")
                svg(style="width:36px; height:36px" viewBox="0 0 24 24")
                  path(fill="white" d="M20,6A2,2 0 0,1 22,8V18A2,2 0 0,1 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10L12,6H20M19.25,13H16V9H14V13H10.75L15,17.25")
            div.msg_text(style="background-color: rgb(33 38 36 / 60%); color: #d8dee2;" v-if="loadedImage")
              .d-flex.align-items-center
                .position-relative.DKLocoarsaTUUIO123456
                  div(style="display: flex; justify-content: space-between;")
                    p(style="font-size: 28px;") Archivo enviado correctamente
                img(src='./../checkmark.png' style='max-width: 50px; width: 100%; height: 50px; padding: 1px; margin-bottom: 12px;')
        //- b-button(variant="bluecolmedica" block size="lg" type="button" @click="onShowModal").text-center.AuthsBtn.mt-4
      //-   p.buttonText.AuthMeds.mb-0 Adjuntar documento
    b-modal(id="introduce" v-model="showModalScan" centered=true hide-footer hide-header)
      .p-5#containerAll
        img(src="@/assets/kiosco46/cancel.svg" alt="cancel" @click="onCloseShow").cancelIcon
        h3.Title.text-center
          strong.normalFont.strong Introduce el documento en la ranura
        h4.text-center.normalFont.Subtitle con las letras hacia arriba.
        img(src="@/assets/kiosco46/cropperGuide.png" alt="imgCropperGuide").imgCropperGuide
        .video-container
          video(autoplay preload)#videoStream
        .fakeHeight
          canvas(style="transform: rotate(-90deg);")#videoWebcam
        .pt-0
        b-button(variant="bluecolmedica" block size="lg" type="button" @click="onScanDoc" style="width: fit-content").text-center.AuthsBtn.my-4
          p.buttonText.AuthMeds.mb-0 Escanear documento
        .pt-5.mt-5(v-if="!showAddDocument")
          BottomTimer(:doThis="() => onCloseShow()" $element="#containerAll" :isNotTimer="true")
    b-modal(id="Agent-not-connect" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
      div(class="p-2 position-relative w-auto h-auto")#sd
        div(class="d-block text-center")
          p(class="my-4 font-weight-bold text-danger") Lo sentimos
          p(class="my-4 text-danger") No fue posible conectarte con {{ meetType != 'agent' ? 'un' : 'cliente' }}
          p(class="my-4 text-danger") {{ meetType != 'agent' ? 'cliente' : 'asesor' }} en este momento
          p(class="my-4") ¿Qué deseas hacer?
        div(class="position-relative")
          b-button(@click="$router.push({ path: '/turno' })" variant="outline-danger" class="btn-block ") Reintentar conectar
          b-button(@click="end" variant="danger" class="btn-block") Cancelar solicitud
      .p-3
        BottomTimer(:doThis="() => onCloseShow()" :isNotTimer="true" $element="#sd")

    b-modal(id="Finalize-call" footerClass='p-2 border-top-0' hide-header centered=true hide-footer)
      #youModal
        img(src="@/assets/kiosco46/cancel.svg" alt="cancel" @click="$bvModal.hide('Finalize-call')").cancelIcon
        div(class="p-2 position-relative w-auto h-auto")
          div(class="d-block text-center" )
            div(class="my-4")
              p.m-0.font-weight-bold.text-danger.Title Estás a punto de finalizar la
              p.m-0.font-weight-bold.text-danger.Title videollamada
            p(class="my-5 color-colmedica").Title ¿Qué deseas hacer?
          div(class="position-relative")
            b-button(variant="outline-bluecolmedica" block size="lg" type="button" @click="$bvModal.hide('Finalize-call')" style="width: 470px; border-radius: 40px;").text-center.AuthsBtn.my-4
              p.buttonText.AuthMeds.mb-0 Volver a la videollamada
            b-button(variant="danger" block size="lg" type="button" @click="end(true)" style="width: 470px; border-radius: 40px;").text-center.AuthsBtn.my-4
              p.buttonText.AuthMeds.mb-0 Finalizar la videollamada
      .py-3
        BottomTimer(:doThis="() => $bvModal.hide('Finalize-call')" :isNotTimer="true" $element="#youModal")

    b-modal(id="Input-selector" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
      div(class="p-2 position-relative w-auto h-auto")
        div(class="d-block text-center")
          div(
            class="toggle my-2"
            v-show="deviceSelector != 'VideoInput' "
          )
            img(id="deviceOff" class="icon-toogle" :src="deviceSelector == 'AudioInput' ? MicOff : VolumeHighOff")
            toggle-button(
              @change="disableDevice(true)"
              :value="toogleDevice"
              :width="140"
              :height="30"
              color="#82C7EB"
              :labels="false"
              class="my-0 mx-2"
            )
            img(id="deviceOn"  class="icon-toogle" :src="deviceSelector == 'AudioInput' ? Mic : VolumeHigh")
          template(v-if="toogleDevice" v-for="device in selectedDeviceAllowed")
            b-button(
              v-if="device && device.deviceId"
              :key="device.deviceId"
              @click="changeDeviceSelected(device)"
              block
              :variant="toogleDevice && deviceCurrent && deviceCurrent.deviceId == device.deviceId ? 'bluecolmedica' : 'outline-bluecolmedica'"
              class="my-2 fontSizeButton"
            ) {{ device.label }}
          //- template(v-if="toogleDevice" v-for="dr in devicesRole")
          //-   b-button(
          //-     v-if="dr"
          //-     :key="dr"
          //-     @click="setRole(dr, device)"
          //-     block
          //-     :variant="toogleDevice && deviceCurrent && deviceCurrent.deviceId == device.deviceId ? 'bluecolmedica' : 'outline-bluecolmedica'"
          //-     class="my-2 fontSizeButton"
          //-   ) {{ dr }}
      BottomTimer(:doThis="() => $bvModal.hide('Input-selector')" :isNotTimer="true")

    b-modal(id="List-status" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer :static="true")
      ModalListStatus(class="p-2 position-relative w-auto h-auto")

    b-modal(id="Allowed-video" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer)
      #foras
        h5 Por favor activa tu cámara.
        .buttons.mt-4
          b-button(@click="startCameraHandler" class="camDialogActivate") Activar
          b-button.ml-2(@click="toogleCamModal" class="camDialogDeactivate") Desactivar
      .pt-3
        BottomTimer(:doThis="() => $bvModal.hide('Allowed-video')" :isNotTimer="true" $element="#foras")

    b-modal(
      id="invalid-mime"
      footerClass='p-2 border-top-0'
      headerClass='p-2 border-bottom-0'
      centered=true
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    )
      h6 Soportamos archivos que pesen menos de 10Mb y sean de tipo imagen (jpeg, tiff, png) y pdf.
      .buttons.mt-4.mimeErrorCloser
        b-button.ml-2(@click="toogleInvalidModal") Cerrar
      BottomTimer(:doThis="() => toogleInvalidModal()" :isNotTimer="true")

    b-modal(
      id="invalid-size"
      footerClass='p-2 border-top-0'
      headerClass='p-2 border-bottom-0'
      centered=true
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    )
      h6 Soportamos archivos que pesen menos de 10Mb y sean de tipo imagen (jpeg, tiff, png) y pdf.
      .buttons.mt-4.sizeErrorCloser
        b-button.ml-2(@click="toogleInvalidModal") Cerrar
      BottomTimer(:doThis="() => toogleInvalidModal()" :isNotTimer="true")
</template>

<script>
import VideoCall from "./components/VideoCall";
import Mobile from "../../utils/evalmobile";
import { mapActions, mapMutations, mapState } from "vuex";
import _get from "lodash/get";
import Attach from "mdi-vue/Attachment.vue";
import Send from "mdi-vue/Send.vue";
import SendOutline from "mdi-vue/SendOutline";
import MonitorShare from "mdi-vue/MonitorShare.vue";
import ListStatus from "mdi-vue/ListStatus.vue";
import ModalListStatus from "./components/ModalListStatus";
import Header from "./components/Header.vue";
import Camera from "mdi-vue/Camera.vue";
import moment from "moment";
import BottomTimer from "./components/BottomTimer";
// import translate from "translate";
import EvalMobile from "@/utils/evalmobile";
import kuid from "kuid";
import ProgressBar from "vue-simple-progress";
import "@/assets/cropper.css";
import Cropper from "cropperjs";

// assets
import LogoColmedica from "@/assets/colmedica_logo.png";
import Mic from "@/assets/newDesign/VolumeHigh.svg";
import MicOff from "@/assets/newDesign/VolumeOff.svg";
import VideoCam from "@/assets/newDesign/CameraHigh.svg";
import VideoCamOff from "@/assets/newDesign/CameraOff.svg";
import VolumeHigh from "@/assets/VolumeHigh.png";
import VolumeHighOff from "@/assets/VolumeHighOff.png";
import PhoneOff from "@/assets/newDesign/PhoneOff.svg";
import Settings from "@/assets/newDesign/Settings.svg";

import LS from "@/helpers/localStorage";
var AWS = require("aws-sdk");
var albumBucketName = process.env.VUE_APP_BUCKET;
var bucketRegion = "us-east-1";
var IdentityPoolId = "us-east-1:26afdf2d-930c-41e5-81a6-7a703020ee16";
// let uploadedfile = [];

export default {
  name: "Meeting-general",

  props: ["meetType", "meetId", "username", "isComponent"],

  components: {
    VideoCall,
    Attach,
    Send,
    SendOutline,
    VolumeHigh,
    Mic,
    VideoCam,
    MonitorShare,
    ListStatus,
    ModalListStatus,
    Camera,
    ProgressBar,
    BottomTimer,
    Header
  },

  data: () => ({
    showAddDocument: false,
    loadingFile: false,
    fileNameLoading: "",
    fileExtension: "",
    showModalSent: false,
    typing: false,
    timer5: null,
    debugMode: false,
    timerDebugMode: false,
    debugCounter: 0,
    isiOS: Mobile.iOS(),
    showPopupConfig: false,
    message: "",
    timerWatcher: null,
    allowChat: true,
    holdIsImagen: false,
    cropper: null,
    modeCropper: false,
    agentcharged: false,
    deviceSelector: null,
    showModalScan: false,
    $videoStream: undefined,
    $videoWebcam: undefined,
    contextWebcam: null,
    interval: null,
    modePicture: true,
    errorVideo: false,
    showMessageProgress: false,
    LogoColmedica,
    // icons
    PhoneOff: PhoneOff,
    Settings: Settings,
    Mic: Mic,
    MicOff: MicOff,
    VideoCam: VideoCam,
    VideoCamOff: VideoCamOff,
    VolumeHigh: VolumeHigh,
    VolumeHighOff: VolumeHighOff,
    validMimeTypes: [
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/octet-stream",
      "image/",
      // "video/",
      // "audio/",
      "application/pdf"
    ],
    translateFormat: null,
    showSendIcon: false,
    loadedImage: false,
    iFinishMeet: false,
    thereWasConnection: false,

    tempFile: null,

    // progress bar
    valueProgress: 0,

    devicesRole: [
      "Principal",
      "Escáner"
    ],

    showImage: false,
    enableConfig: 0
  }),

  async created() {
    this.startup();
    this.enableConfig = 0;

    setTimeout(() => {
      let trackingData = {
        uuid: window.person.id,
        accion: this.meetType + "-created-instanciar-videollamada",
        debmedia_turn_code: this.turn?.code,
        url_origen: window.location.href,
        origen: _get(this.this_user_data, "fu") || "ad",
        mensajes: JSON.stringify({
          meetingTitle: this.meetingTitle,
          "params.meetId": this.$route.params.meetId,
          meetId: this.meetId
        })
      };

      this.sendTracking(trackingData);
    }, 1000);

    // Check permissions
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      });
    } catch (error) {
      alert("Se ha presentado un inconveniente con la cámara: " + error);
    }
  },

  mounted() {
    document.body.onclick = () => {
      this.showPopupConfig = false;
    };

    setTimeout(() => {
      AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IdentityPoolId
        })
      });

      let trackingData = {
        uuid: window.person.id,
        accion: this.meetType + "-mounted-instanciar-videollamada",
        debmedia_turn_code: this.turn?.code,
        url_origen: window.location.href,
        origen: _get(this.this_user_data, "fu") || "ad",
        mensajes: JSON.stringify({
          meetingTitle: this.meetingTitle,
          "params.meetId": this.$route.params.meetId,
          meetId: this.meetId
        })
      };

      this.sendTracking(trackingData);
    }, 1000);
  },

  destroyed() {
    if (this.timer5) {
      clearTimeout(this.timer5);
    }

    setTimeout(() => {
      let trackingData = {
        uuid: window.person.id,
        accion: this.meetType + "-destroyed-instanciar-videollamada",
        debmedia_turn_code: this.turn?.code,
        url_origen: window.location.href,
        origen: _get(this.this_user_data, "fu") || "ad",
        mensajes: JSON.stringify({
          meetingTitle: this.meetingTitle,
          "params.meetId": this.$route.params.meetId,
          meetId: this.meetId
        })
      };

      this.sendTracking(trackingData);
    }, 1000);
  },

  watch: {
    turnStatus(val) {
      if (val && val == "FINALIZED") this.end();
    },
    showMessageProgress(val) {
      if (val) {
        if (!this.timer5) {
          this.timer5 = setTimeout(() => {
            this.showMessageProgress = false;
            clearTimeout(this.timer5);
            this.timer5 = null;
          }, 5000);
        }
      }
    },
    attendees(val) {
      if (val.length >= 2) {
        this.allowChat = true;
        this.agentController(true);

        // tracking
        let font = _get(this.c_data, "fu", "");
        font = !font || font == "" ? "ad" : font;
        let trackingData = {
          uuid: window.person.id,
          accion: this.meetType + "-conexion-videollamada",
          debmedia_turn_code: this.turn?.code,
          url_origen: window.location.href,
          origen: font,
          mensajes: ""
        };

        this.sendTracking(trackingData);
        this.thereWasConnection = true;
        this.$emit("attendees", false);

        // Check audio and video
        setTimeout(() => {
          if (!this.localVideo) {
            this.startCameraHandler();
            this.toogleAudioEnabled();
          }
        }, 10000);
      } else {
        if (!this.thereWasConnection) this.$emit("attendees", true);
      }
    },

    messages(val) {
      let msg = val[this.messages.length - 1];
      if (!msg.isSelf && msg.text == "33Silver49Finalizado") {
        this.end();
      }
      this.$nextTick(() => {
        this.modeScroll(val.length);
      });
    },

    loadingFile(val) {
      if (val) {
        this.$nextTick(() => {
          this.modeScroll(this.messages.length);
        });
      }
    },

    m_allow_video(val) {
      if (val) {
        this.$bvModal.show("Allowed-video");
      } else {
        this.$bvModal.hide("Allowed-video");
      }
    },

    // if the videid chine is not the same
    meetData(meet) {
      if (this.meetType === "agent") {
        if (this.meetId && meet.id) {
          if (this.meetId != meet.id) {
            location.reload();
          }
        }
      }
    }
  },

  computed: {
    ...mapState({
      version: state => state.version,
      meeting: state => state.meeting.meeting,
      retrieving: state => state.meeting.connecting,
      audioId: state => state.meeting.audioId,
      videosIds: state => state.meeting.videosIds,
      attendees: state => state.meeting.attendees,
      videoInputDevices: state => state.meeting.videoInputDevices,
      audioOutputDevices: state => state.meeting.audioOutputDevices,
      audioInputDevices: state => state.meeting.audioInputDevices,
      // devices
      speakerDevice: state => state.meeting.speakerDevice,
      videoDevice: state => state.meeting.videoDevice,
      audioDevice: state => state.meeting.audioDevice,
      // toggles
      audioEnabled: state => state.meeting.audioEnabled,
      camEnabled: state => state.meeting.camEnabled,
      speakerEnabled: state => state.meeting.speakerEnabled,
      videoStopSuggestion: state => state.meeting.videoStopSuggestion,
      isAgent: state => state.meeting.isAgent,

      messages: state => state.meeting.messages,
      sharingEnabled: state => state.meeting.sharingEnabled,
      c_data: state => state.client_data,

      waiting_time: state => state.max_waiting_time,
      turn: state => state.turn,
      m_allow_video: state => state.meeting.m_allow_video,
      env: state => state.env,

      actualTurn() {
        return _get(this.turn, "jsonDetails.actualTurn", "canceled");
      },

      assingTurn() {
        return _get(this.turn, "jsonDetails.turn");
      },

      helperAgent() {
        return this.turn.module?.worker_name || "";
      },

      meetData() {
        return _get(this.turn, "meet");
      },

      clientStorage() {
        try {
          let l_c = LS.getItem("dclient_data");
          return l_c.na + " " + l_c.ap;
        } catch (error) {
          console.log(error);
          return "";
        }
      }
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    },

    agentData() {
      const agent = this.turn.worker?.actualUser.uUser;
      return agent || null;
    },

    clientData() {
      // const isSelf = this.meeting.Attendee.AttendeeId ===
      //   this.agent;
      return this.turn?.worker?.actualTurn; // si viene de silver es agente, de lo contrario client
    },

    nameDataShowable() {
      return this.isAgent
        ? this.clientData
          ? `Cliente: ${this.clientData.getFullName}`
          : "No se encontró el cliente"
        : this.agentData
        ? `Asesor: ${this.agentData.firstName} ${this.agentData.lastName}`
        : "No se encontró el asesor";
    },

    debugButton() {
      return (
        this.env.VUE_APP_DEBUG_BUTTON === "true" ||
        this.meetType === "agent" ||
        this.debugMode
      );
    },

    meetingTitle() {
      return _get(this.meeting, "Meeting.Meeting.ExternalMeetingId");
    },

    turnStatus() {
      return _get(this.turn, "status");
    },

    meetingUrl() {
      return `${location.origin}/#/meeting/${this.meetingTitle}`;
    },

    assignerURL() {
      return `${location.origin}/#/turn_assigner/${this.meetingTitle}`;
    },

    selectedDeviceAllowed() {
      if (this.deviceSelector == "VideoInput") return this.videoInputDevices;
      if (this.deviceSelector == "AudioInput") return this.audioInputDevices;
      if (this.deviceSelector == "AudioOutput") return this.audioOutputDevices;
      return null;
    },

    deviceCurrent() {
      if (this.deviceSelector == "VideoInput") return this.videoDevice;
      if (this.deviceSelector == "AudioInput") return this.audioDevice;
      if (this.deviceSelector == "AudioOutput") return this.speakerDevice;
      return null;
    },

    toogleDevice() {
      if (this.deviceSelector == "VideoInput") return this.camEnabled;
      if (this.deviceSelector == "AudioInput") return this.audioEnabled;
      if (this.deviceSelector == "AudioOutput") return this.speakerEnabled;
      return false;
    },

    timeDay() {
      const dateMoment = moment().format("dddd, MMMM");
      const day = dateMoment.split(",")[0];
      const month = dateMoment.split(",")[1];
      const dayTranslation = this.evaluationDays(day);
      const monthTranslation = this.evaluationMonths(month);
      return `${dayTranslation}, ${moment().format(
        "D"
      )} de ${monthTranslation.toLowerCase()} de ${moment().format("YYYY")}`;
    },

    UsableHeight() {
      return window.innerHeight + "px";
    },

    isIOS() {
      return EvalMobile.iOS();
    },

    isSharedVideo() {
      return !!this.videosIds.find(vid => vid.isContent);
    },

    localVideo() {
      let result = this.videosIds.filter(vid => vid.localTile);
      return _get(result, "[0].active", false);
    }
  },

  methods: {
    ...mapMutations({
      toogleCamModal: "meeting/toogleCamModal"
    }),
    ...mapActions({
      setMeetType: "meeting/setMeetType",
      startCamera: "meeting/startCamera",
      setAtendeeName: "meeting/setAtendeeName",
      create: "meeting/startMeet",
      endMeet: "meeting/endMeet",
      changeVideoDevice: "meeting/changeVideoDevice",
      sendMessage: "meeting/sendMessage",
      changeSpeakerDevice: "meeting/changeSpeakerDevice",
      changeAudioDevice: "meeting/changeAudioDevice",
      // toogles
      toogleSharingEnabled: "meeting/toogleSharingEnabled",
      toogleCamEnabled: "meeting/toogleCamEnabled",
      toogleSpeakerEnabled: "meeting/toogleSpeakerEnabled",
      toogleAudioEnabled: "meeting/toogleAudioEnabled",
      // sendFile: "meeting/sendFile",
      putLog: "log/put",
      getTurnInfo: "getTurnInfo",
      getTurnCoorelation: "getTurnCoorelation",
      sendTracking: "virtualrow/handleTracking",
      setConfigCameras: "meeting/setConfigCameras"
    }),

    debugModeCounter() {
      ++this.debugCounter;
      if (this.timerDebugMode) clearTimeout(this.timerDebugMode);
      if (this.debugCounter >= 5) {
        this.debugMode = !this.debugMode;
        this.debugCounter = 0;
      }
      this.timerDebugMode = setTimeout(() => {
        this.debugCounter = 0;
      }, 500);
    },

    showMsgBoxTwo(status) {
      this.$bvModal.msgBoxOk(
        status
          ? "Se ha restablecido la conexión de video."
          : "Se desconecta el video por problemas de red.",
        {
          title: "Evento de video",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true
        }
      );
    },

    async startup() {
      this.setMeetType(true); // this.meetType === "agent" // TODO: Marcelo y Karen piden esto.

      // Assign config
      this.setConfigCameras({
        kioscos: this.env.VUE_APP_KIOSCOS,
        params: this.$route.query
      });

      // if the component is call from turn
      if (this.isComponent) {
        try {
          this.setAtendeeName(
            this.$route.params.username || this.username || "Sin nombre"
          );

          let meetId = this.$route.params.meetId || this.meetId;
          // if (!meetId) {
          //   // this.turn = this.$route.params.turnId;
          //   // this.message = 'Obteniendo correlación...';
          //   const coo = await this.getTurnCoorelation(
          //     LS.getItem("coor_turnId")
          //   );
          //   this.getTurnInfo(coo.turn_code);

          //   meetId = coo.meet_id;
          // }

          await this.create({
            meetId: meetId,
            username: this.$route.params.username || this.username,
            turnCode: this.turn.code
          });

          this.putLog({
            name: "Meeting. Se ha creado la videollamada."
          });

          // this.agentController(); // TODO: enable it in demo to clients
        } catch (error) {
          this.putLog({
            name:
              "Meeting. Error restrictivo. No fue posible establecer la videollamada.",
            checked: false,
            message: error
          });
          this.$rollbar.warning("Stop meeting because a error", { error });
          this.$emit("endMeet");
        }
      } else {
        try {
          this.setAtendeeName(
            this.$route.params.username || this.username || "Sin nombre"
          );

          let meetId = this.$route.params.meetId || this.meetId;
          // if (this.meetType === "agent") {
          //   if (!meetId) {
          //     // this.turn = this.$route.params.turnId;
          //     // this.message = 'Obteniendo correlación...';
          //     const coo = await this.getTurnCoorelation(
          //       LS.getItem("coor_turnId")
          //     );
          //     this.getTurnInfo(coo.turn_code);

          //     meetId = coo.meet_id;
          //   }
          // }

          await this.create({
            meetId: meetId,
            username: this.$route.params.username || this.username,
            turnCode: this.turn.code
          });

          this.putLog({
            name: "Meeting. Se ha creado la videollamada."
          });
          // this.agentController(); // TODO: enable it in demo to clients
        } catch (error) {
          this.putLog({
            name:
              "Meeting. Error restrictivo. No fue posible establecer la videollamada.",
            checked: false,
            message: error
          });
          this.$rollbar.warning("Stop meeting because a error", { error });
          this.$emit("endMeet");
          this.endMeet();
        }
      }
    },

    startCameraHandler() {
      this.startCamera();
      setTimeout(() => {
        this.toogleCamModal();
      }, 1000);
    },

    async enableCamera() {
      await this.startCamera();
    },

    messageHandler() {
      
      this.sendMessage({
        message: this.message + " name:" + this.clientStorage
      });
      this.message = "";
      this.showSendIcon = false;
    },

    async end(click = false) {
      // tracking
      if (click) {
        let font = _get(this.c_data, "fu", "");
        font = !font || font == "" ? "ad" : font;
        let trackingData = {
          uuid: window.person.id,
          accion: this.meetType + "-finish-videollamada",
          debmedia_turn_code: this.turn?.code,
          url_origen: window.location.href,
          origen: font,
          mensajes: ""
        };
        this.sendTracking(trackingData);
      }

      setTimeout(async () => {
        this.sendMessage({ message: "33Silver49Finalizado" });
        await this.endMeet();
        this.iFinishMeet = true;
        this.$emit("endMeet");
        this.$router.push({path: "/finished_fv_atril", query: this.$route.query});
      }, 1000);
    },

    timeFormat(time) {
      return moment(time).format("hh:mm a");
    },

    agentController(canceled = false) {
      if (canceled == true) {
        clearTimeout(this.timerWatcher);
        this.timerWatcher = null;
        return;
      }
      this.timerWatcher = setTimeout(() => {
        this.$bvModal.show("Agent-not-connect");
      }, this.waiting_time * 1000);
    },

    fileHandlerImage() {
      this.fileHandler(this.$refs.image);
    },

    fileHandlerGeneral() {
      this.fileHandler(this.$refs.file);
    },

    toogleInvalidModal() {
      this.$bvModal.hide("invalid-mime");
      this.$bvModal.hide("invalid-size");
    },

    async fileHandler(reference) {
      const files = reference.files;
      if (!files[0]) return;
      const infoFile = {
        name: files[0].name,
        ext: files[0].name.split(".")[files[0].name.split(".").length - 1],
        mime: files[0].type,
        size: files[0].size
      };
      const isValidSize = infoFile.size < 10000000;
      if (!isValidSize) {
        this.$bvModal.show("invalid-size");
        return;
      }
      this.loadingFile = true;
      this.fileNameLoading = infoFile.name.split(".")[0];
      this.fileExtension = this.getFileExtension(infoFile.name);

      try {
        const { signed } = await this.fileHandlerSender(files[0]);
        const template = `
            <div class="position-relative DKLocoarsaTUUIO123456">
              <div style="display: flex; justify-content: space-between;">
                <p style="margin: 0;" class="fileDkSend">Archivo enviado correctamente</p>
                <img src="./../checkmark.png" style="max-width: 19px; width: 100%; height: 19px;" />
              </div>
              <a class="folderIcon-DK" download="${
                files[0].name
              }" target="_blank" style="display: flex;">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="#55bce2" d="M20,6A2,2 0 0,1 22,8V18A2,2 0 0,1 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10L12,6H20M19.25,13H16V9H14V13H10.75L15,17.25" />
              </svg>
              <p style="margin: 0; color: #55bce2; text-decoration: underline; text-truncate; text-overflow: ellipsis; overflow: hidden; max-width: 75%;" class="fileChatOK text-truncate">${
                files[0].name.split(".")[0]
              }</p>
              <span style="color: #55bce2; text-decoration: underline;">.${this.getFileExtension(
                files[0]?.name
              )}</span>
            </a>
          </div>
        `;
        const timer = setTimeout(() => {
          this.sendMessage({ message: template });
          this.sendMessage({ message: "hidden-attach:" + signed });
          this.loadingFile = false;
          this.fileNameLoading = "";
          this.fileExtension = "";
          clearTimeout(timer);
        }, 200);
      } catch (error) {
        this.loadingFile = false;
        this.sendMessage({
          message: `
            <div class="position-relative">
              <i style="font-size: 0.7rem; color: brown;">Tu archivo ${files[0].name} no pudo ser cargado. Inténtalo de nuevo.</i>
            </div>
          `,
          local: true
        });
        window.RB.warning("Invalid file to charge", { infoFile });

        // tracking
        let font = _get(this.c_data, "fu", "");
        font = !font || font == "" ? "ad" : font;
        let trackingData = {
          uuid: window.person.id,
          accion: "error-send-file-chat",
          debmedia_turn_code: this.turn?.code,
          url_origen: window.location.href,
          origen: font,
          mensajes: error + " " + JSON.stringify(this.tempFile)
        };

        this.sendTracking(trackingData);
      }
      reference.value = "";
      this.showModalScan = false;
      this.descartDocumentBack(true);
    },

    async simulateAttach() {
      this.simulateClick(this.$refs.file);
    },

    async simulateImage() {
      this.simulateClick(this.$refs.image);
    },

    async fileHandlerSender(file) {
      const result = {
        turn_code: this.turn?.code,
        call_id: this.turn?.code, // FIXME: Code ... check current meeting id
        name: file.name,
        type: file.type,
        b64: file,
        size: file.size
      };

      this.tempFile = result;

      // let key = await this.sendFile(result);
      this.preloadFile = true;
      let files = await this.uploadFilesToS3(result);
      this.preloadFile = false;
      return files;
    },
    readed(file) {
      return new Promise((res, rej) => {
        var reader = new FileReader();
        reader.onload = () => res(reader.result);
        reader.onerror = err => rej(err);
        reader.readAsDataURL(file);
      });
    },

    simulateClick(elem) {
      // Create our event (with options)
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      });
      // If cancelled, don't dispatch our event
      elem.dispatchEvent(evt);
    },

    selectedDeviceInput(devices) {
      this.deviceSelector = devices;
      this.$bvModal.show("Input-selector");
    },

    changeDeviceSelected(device) {
      if (this.deviceSelector == "VideoInput") this.changeVideoDevice(device);
      if (this.deviceSelector == "AudioInput") this.changeAudioDevice(device);
      if (this.deviceSelector == "AudioOutput")
        this.changeSpeakerDevice(device);
      this.closeModalDevices();
    },

    disableDevice() {
      if (this.deviceSelector == "VideoInput") this.toogleCamEnabled();
      if (this.deviceSelector == "AudioInput") this.toogleAudioEnabled();
      if (this.deviceSelector == "AudioOutput") this.toogleSpeakerEnabled();
      this.closeModalDevices();
    },

    startCamFromBasis() {
      if (!this.videoDevice) this.toogleCamModal();
      else this.toogleCamEnabled();
    },

    closeModalDevices() {
      // close modal and leave selector at null
      setTimeout(() => {
        this.$bvModal.hide("Input-selector");
      }, 600);
    },

    handleChangeMessage() {
      this.showSendIcon = true;
    },

    getFileExtension(filename) {
      return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    },

    modeScroll(messageNumber) {
      var myMsg = document.getElementById(`message_${messageNumber - 1}`);
      if (myMsg) {
        var topPos = myMsg.offsetTop;
        document.getElementById("full_messages").scrollTop = topPos;
      }
    },

    evaluationMonths(month) {
      let monthTranslation;
      if (month.includes("January")) monthTranslation = "Enero";
      else if (month.includes("February")) monthTranslation = "Febrero";
      else if (month.includes("March")) monthTranslation = "Marzo";
      else if (month.includes("April")) monthTranslation = "Abril";
      else if (month.includes("May")) monthTranslation = "Mayo";
      else if (month.includes("June")) monthTranslation = "Junio";
      else if (month.includes("July")) monthTranslation = "Julio";
      else if (month.includes("August")) monthTranslation = "Agosto";
      else if (month.includes("September")) monthTranslation = "Septiembre";
      else if (month.includes("October")) monthTranslation = "Octubre";
      else if (month.includes("November")) monthTranslation = "Noviembre";
      else if (month.includes("December")) monthTranslation = "Diciembre";
      else monthTranslation = month;
      return monthTranslation;
    },

    evaluationDays(day) {
      let dayTranslation;
      switch (day) {
        case "Monday":
          dayTranslation = "Lunes";
          break;
        case "Tuesday":
          dayTranslation = "Martes";
          break;
        case "Wednesday":
          dayTranslation = "Miércoles";
          break;
        case "Thursday":
          dayTranslation = "Jueves";
          break;
        case "Friday":
          dayTranslation = "Viernes";
          break;
        case "Saturday":
          dayTranslation = "Sábado";
          break;
        default:
          dayTranslation = "Domingo";
          break;
      }
      return dayTranslation;
    },

    signObject(key) {
      return new Promise(resolve => {
        try {
          // let key_ = '/' + key.url.split('prod/')[1].split('.')[0];
          let s3 = new AWS.S3();

          const signedUrlExpireSeconds = 60 * 30; // your expiry time in seconds.
          let res = s3.getSignedUrl("getObject", {
            Bucket: albumBucketName,
            Key: key,
            Expires: signedUrlExpireSeconds
          });

          resolve(res);
        } catch (error) {
          console.log(error);
          resolve(key);
        }
      });
    },

    uploadFilesToS3(element) {
      this.loadedImage = false;
      return new Promise(resolve => {
        let nameSplitted = element.name.split(".");
        let mom = moment().utc();
        let nameOfUuidfile = `${kuid()}.${
          nameSplitted[nameSplitted.length - 1]
        }`;
        let hour = mom.format("HH");
        var timedate = mom.format("DDMMYYYY");
        var file = element.b64;
        this.valueProgress = 0;

        // var xhr = new XMLHttpRequest();
        const destiny = `${timedate}/${hour}/${element.call_id}/chat_files/${nameOfUuidfile}`;

        var opts = { queueSize: 1, partSize: 1024 * 1024 * 5 };
        var params = { Key: destiny, ContentType: file.type, Body: file };

        let s3 = new AWS.S3({
          params: {
            Bucket: albumBucketName,
            Key: destiny,
            Body: file
          }
        });
        var upload = s3
          .upload(params, opts)
          .on("httpUploadProgress", evt => {
            this.valueProgress = parseInt((evt.loaded * 100) / evt.total);
          })
          .promise();

        /* eslint-disable */
        upload.then(async (data, err) => {
          if(!err){
            console.log("************* Successfully uploaded video. *****************");
            console.log(data)
            let s_key = await this.signObject(destiny);
            let obj_t = {
              signed: s_key,
              // url: this.env.VUE_APP_FILE_SERVER + '/' + destiny
              url: destiny
            };
            this.loadedImage = true;
            // uploadedfile.push(obj_t);
            resolve(obj_t);
          } else {
            console.log("************* ERROR! *****************");
            console.log(err)
          }
        });
      } )

    },

    clearAll() {
      this.contextWebcam = null;
      this.$videoStream = undefined;
      this.$videoWebcam = undefined;

      this.interval = null;

      this.modePicture = true;
      this.src = "";
      this.modeCropper = false;
    },

    onDiscard() {
      // this.requestMedia();
      this.descartDocumentBack(true);
      this.clearAll();
      const timer = setTimeout(() => {
        this.onShowModal();
        clearTimeout(timer);
      }, 150);
    },

    onScanDoc() {
      this.$videoStream.pause();

      this.modePicture = false;

      // picture in canvan for rotate actions
      this.contextWebcam?.drawImage(this.$videoStream, 0, 0);

      const timeout = setTimeout(() => {
        // this.$refs.capture.style.display = "none";
        this.$videoWebcam.toBlob(async blob => {
          let file = new File([blob], "fileName.jpg", { type: "image/jpeg" });
          this.compressImagen = true;
          let fileToCompress = {};
          this.holdIsImagen = file.type.indexOf("image") !== -1;
          if (this.holdIsImagen) {
            try {
              fileToCompress = await this.compressorJsImage(file);
            } catch (error) {
              fileToCompress = file;
            }
            this.urlData = URL.createObjectURL(fileToCompress);

            const timeout = setTimeout(() => {
              this.initialCropper();
              clearTimeout(timeout);
            }, 10);

            this.holdDocument = await this.readed(fileToCompress);
          } else {
            this.holdDocument = await this.readed(file);
          }
          // this.infoSizeImagen = {
          //   uncompress: infoFile.size,
          //   compress: fileToCompress.size || infoFile.size
          // };
          this.compressImagen = false;
          document.querySelector("#AddDocument").style.display = "block";
          this.showAddDocument = true;
          document.querySelector("#OverlayDocument").style.display = "block";
          // this.showModalScan = false;
        });

        clearTimeout(timeout);
      }, 10);
    },

    descartDocument() {
      document.querySelector("#AddDocument").style.display = "none";
      this.showAddDocument = false;
      document.querySelector("#OverlayDocument").style.display = "none";
      this.holdDocument = null;
    },

    descartDocumentBack(discard) {
      this.cropper?.destroy();
      this.cropper = null;
      if (discard) this.descartDocument();
    },

    onCloseShow() {
      this.showModalScan = false;
      this.urlData = null;
      this.cropper = null;
    },

    activateCropper() {
      const newUrl = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");

      this.modeCropper = true;
      this.urlData = newUrl;

      const timeout = setTimeout(() => {
        this.descartDocumentBack(false);
        this.cropper = new Cropper(this.$refs.fileImage, {
          autoCrop: true,
          viewMode: 1,
          dragMode: "move",
          background: false,
          cropBoxMovable: true,
          cropBoxResizable: true,
          crop(event) {
            this.widthBox = event.detail.x;
            this.heightBox = event.detail.y;
          }
        });

        this.cropper.replace(this.urlData);
        clearTimeout(timeout);
      }, 10);
    },

    initialCropper() {
      this.showImage = false;
      this.descartDocumentBack(false);
      this.cropper = new Cropper(this.$refs.fileImage, {
        autoCrop: false,
        viewMode: 1,
        dragMode: "move",
        background: false,
        cropBoxMovable: true,
        cropBoxResizable: true,
        crop(event) {
          this.widthBox = event.detail.x;
          this.heightBox = event.detail.y;
        }
      });

      this.cropper.replace(this.urlData);
      setTimeout(() => {
        this.onRotation(-90);
        this.showImage = true;
      }, 500);
    },

    onShowModal() {
      this.showModalScan = true;
      this.requestMedia();
    },

    loadXHR(url) {
      return new Promise(function(resolve, reject) {
        try {
          let xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.responseType = "blob";
          xhr.onerror = function() {reject("Network error.")};
          xhr.onload = function() {
              if (xhr.status === 200) {resolve(xhr.response)}
              else {reject("Loading error:" + xhr.statusText)}
          };
          xhr.send();
        }
        catch(err) {reject(err.message)}
      });
    },

    onCLoseSent() {
      this.showModalSent = false;
    },

    addDocument() {
      this.loadXHR(this.cropper.getCroppedCanvas().toDataURL("image/jpeg")).then(blob => {
        this.showModalSent = true;
        let file = new File([blob], "document.jpg", { type: "image/jpeg" });
        this.showModalScan = false;
        this.descartDocument(true);
        this.fileHandler({ files: [file] });
        this.showMessageProgress = true;
      }).catch(console.error);
    },

    requestMedia() {
      this.modePicture = true;
      navigator.mediaDevices.enumerateDevices().then(devices => {
        let cameras = devices.filter(dev => dev.kind === "videoinput");
        
        // check config cameras
        console.log(this.env)
        let kioscos = this.env.VUE_APP_KIOSCOS;
        let params = this.$route.query;
        let camerasScan = [];
        if(kioscos[params.branchid]){
          camerasScan = cameras.filter(v=> v.label.toLowerCase().includes(kioscos[params.branchid].cameras?.documents?.toLowerCase()));
        } else {
          camerasScan = cameras.filter((v=> v.label.includes("Q800L")));
        }
        
        let device;
        if (camerasScan.length >= 1) device = camerasScan[0];
        else device = cameras[0];
        const constraints = {
          video: {
            deviceId: device.deviceId,
            width: 1920,
            height: 1080
          }
        };
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(this.onSuccessVideo)
          .catch(this.onErrorVideo);
      });
    },

    onRotation(deg) {
      this.cropper.rotate(deg);
    },

    onCancel(newUrl) {
      this.modeCropper = false;
      this.initialCropper();
    },

    onShowConfig(e) {
      e.stopPropagation();
      this.showPopupConfig = true;
    },

    onAccept() {
      const newUrl = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");

      this.urlData = newUrl;
      const timer = setTimeout(() => {
        this.onCancel(newUrl);
        clearTimeout(timer);
      }, 0);
    },

    onSuccessVideo(stream) {
      this.$videoStream = document.getElementById("videoStream");
      this.$videoWebcam = document.getElementById("videoWebcam");

      this.contextWebcam = this.$videoWebcam.getContext("2d");
      this.$videoStream.srcObject = stream;

      this.$videoStream.onloadedmetadata = () => {
        // this.$videoWebcam.width = this.$videoStream.videoWidth;
        // this.$videoWebcam.height = this.$videoStream.videoHeight;
        this.$videoWebcam.width = this.$videoStream.videoWidth;
        this.$videoWebcam.height = this.$videoStream.videoHeight;
      };
      this.$videoStream.play();
      // this.initDrawCanvas();
    },

    onErrorVideo(error) {
      console.error(error);
      this.errorVideo = true;
    },

    initDrawCanvas() {
      // this.interval = setInterval(() => {
      //   this.contextWebcam?.drawImage(this.$videoStream, 0, 0);

      //   if (!this.modePicture) {
      //     clearInterval(this.interval);
      //   }
      // }, 50);
    },

    download(id){
      // if is attachmen, must arrive another message with url
      if(this.messages[id].text.includes('DKLocoarsaTUUIO123456')){
        try {
          let attach_url = this.messages[id+1].text.replace('hidden-attach:', '');
          let nameFile =  this.messages[id].text.match(/download=.([^"]+)/)[1];
          
          let a= document.createElement('a');
          a.target= '_blank';
          a.download= nameFile;
          a.href= attach_url;
          a.click();

        } catch (error) {
          console.log(error)
        }
      }
    },

    nameParticipant(text, alt){
      let tsp = text.split('name:');
      return tsp.length > 1 ? tsp[1] : alt;
    },

    setRole(role, device){
      localStorage.setItem(role, device)
    }

  }
};

</script>

<style scoped>
.videoStopSuggestionMessage {
  position: absolute;
  bottom: -5px;
  text-align: center;
  width: 100%;
  font-size: smaller;
  z-index: 3;
}
.container_meet {
  /* overflow-x: auto; */
  margin-right: auto;
  margin-left: auto;
}
.header {
  height: 6%;
}

#videoStream {
  /* display: none; */
  /* width: 1393px;
    height: 1260px; */
  width: 100%;
  height: 300px;
  margin-left: -130px;
  /* transform: rotate(-90deg); */
  object-position: center;
  object-fit: contain;
  margin-bottom: -9px;
  /* padding: 50px 0; */
  /* margin-left: -476px; */
}

#videoWebcam {
  display: none;
  visibility: hidden;
}

.videozone {
  height: 355px;
  max-width: 900px;
  margin: auto;
}
.chatzone {
  height: calc(60% - 30px);
}

.cancelIcon {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 30px;
}

.Page {
  position: fixed;
  bottom: 140px;
  height: calc(50vh - 105px);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.containerMsg {
  padding: 8px;
  padding-top: 10px;
  height: calc(100% - 4rem);
  position: relative;
}

.messagesZone {
  height: calc(100% - 56px - 30px);
  overflow: auto;
  position: relative;
}

.fullChat {
  background-color: #e9ebf2;
  border-radius: 10px;
  height: 100%;
  max-width: 950px;
  margin: auto;
}

.dateTittle {
  text-align: center;
  margin: 0px 5px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  color: #949da5;
  border-bottom: 1px solid #6c757d;
  padding: 0 5px;
}

.fileChatOK {
  width: 50%;
  min-width: 80px;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dateTittle span:nth-child(1) {
  font-size: 0.9rem;
}

/* .dateTittle span:nth-child(1) {
  color: #54678f;
  font-weight: 600;
  width: 40%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: start;
} */

.img_cont_msg {
  height: 40px;
  width: 40px;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 5px; /* 25px; */
  position: relative;
  max-width: 100%;
}

.msg_text {
  padding: 5px 10px;
  border-radius: 5px;
  overflow: auto;
  font-size: 0.95rem;
}

.msg_unique_text {
  padding: 5px 10px;
  border-radius: 0;
  overflow: auto;
  font-size: 0.95rem;
}

.rotatedk {
  transform: rotate(240deg);
}

.folderIcon-DK {
  /* // width: 100px; */
  height: 80px;
  padding-top: 50px;
  /* // border: solid 1px #000; */
  position: relative;
  display: flex;
  text-align: center;
}

.text-truncate {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.uniqueMessage {
  margin: 0;
  margin-bottom: 0 !important;
  opacity: 0;
  animation: opacityToggle 1s forwards ease-in-out;
}

.loading_msg-dk {
  border-radius: 5px 5px 0 0;
}

.marginNegative {
  margin-top: -5px;
  opacity: 0;
  animation: opacityToggle 1s forwards ease-in-out;
}

.marginNegative .msg_cotainer {
  width: 100%;
}

.image_msg-dk {
  background: url(./../loading-buffering.gif);
  background-size: cover;
  height: 19px;
  max-width: 19px;
  background-repeat: no-repeat;
  animation: changeImage 1s forwards ease-in-out;
}

.uniqueMessage .msg_cotainer {
  border-radius: 0;
  width: 100%;
}

.msg_owner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  color: #6d7074;
}
.msg_owner_client {
  text-align: right;
}
.msg_owner_agent {
  text-align: left;
}

.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 5px; /* 25px; */
  background-color: #c3d9c8; /* #78e08f; */
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
}

.msg_time {
  left: 0;
  bottom: -15px;
  color: darkgray;
  font-size: 10px;
  text-align: right;
}

.msg_time_send {
  text-align: right;
  right: 0;
  bottom: -15px;
  color: darkgray;
  font-size: 10px;
  margin: 0px 10px;
  display: block;
}
.attach_btn {
  /* border-radius: 5px 0 0 5px !important; 15px 0 0 15px !important; */
  background-color: rgba(0, 0, 0, 0.1) !important;
  /* border: 0 !important; */
  color: #6c757d !important;
  cursor: pointer;
  height: auto;
  display: block;
}
.imgCropperGuide {
  width: 100%;
  height: 180px;
  object-fit: contain;
  margin-bottom: -142px;
}

.send_btn {
  border-radius: 0 5px 5px 0 !important; /* 0 15px 15px 0 !important; */
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
  color: #6c757d !important;
  height: auto;
}
.type_msg {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
  color: #6c757d !important;
  height: 40px !important;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.bottomzone {
  /* position: absolute;
  bottom: 0;
  width: 100%; */
  margin-top: -50px;
}
.chatWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.d-lfex-dk-dk {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .chatWrapper {
    min-height: 400px;
  }
}

.size-buttons {
  height: 35px;
  margin: 0px auto;
  position: relative;
  display: block;
}

.size-buttonMic {
  height: 60px;
  margin: 0px auto;
  position: relative;
  display: block;
}

/* tootle */
.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-toogle {
  height: 40px;
}

.fontSizeButton {
  font-size: 14px;
}

.version-position {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 8px;
  color: #6c757d;
}

.justify-content-start {
  max-width: 80%;
}

.justify-content-end {
  display: flex;
  align-self: flex-end;
  -webkit-align-self: flex-end;
}

.green-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #2cb9b0;
  margin-right: 5px;
}

.video-container {
  width: 618px;
  height: 642px;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -90px;
  position: relative;
  z-index: -1;
}

.red-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #b92c2c;
  margin-right: 5px;
}

.lds-ellipsis {
  display: flex;
  position: relative;
  width: 50px;
  align-items: center;
  height: 30px;
}
.lds-ellipsis div {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 5px;
  animation: lds-ellipsis1 0.8s infinite ease-in-out;
}
.lds-ellipsis div:nth-child(2) {
  left: 5px;
  animation: lds-ellipsis2 0.8s infinite ease-in-out alternate;
}
.lds-ellipsis div:nth-child(3) {
  left: 17px;
  animation: lds-ellipsis2 0.8s infinite ease-in-out alternate;
}
.lds-ellipsis div:nth-child(4) {
  left: 33px;
  animation: lds-ellipsis3 0.8s infinite ease-in-out alternate;
}
.typing {
  border-radius: 10px;
  background-color: #2e303e;
  width: 42px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-self: flex-end;
  -webkit-align-self: flex-end;
  position: relative;
}
.ModalContent {
  position: fixed;
  z-index: 20000000000;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  background-color: #081e3d;
  display: none;
  width: 100%;
  max-width: 637px;
  overflow: auto;
  height: 1066px;
  bottom: 137px;
}

.ModalContent .BottomTimer {
  background-color: white;
}

.typing-2 {
  border-radius: 10px;
  background-color: #54678f;
  width: 42px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  position: relative;
}

.triangulo {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #212624;
  border-top: 19px solid #0000;
  border-bottom: 0px solid #0000;
  /* top: 0; */
  bottom: 0px;
  right: -5px;
}

.disabled-triangle {
  border-left: 10px solid rgb(105 117 112);
  /* border-top: 19px solid rgb(33 38 36 / 60%); */
  border-bottom: 0px solid rgb(33 38 36 / 60%);
}

.triangulo-chat {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #2e303e;
  border-top: 19px solid #0000;
  border-bottom: 0px solid #0000;
  /* top: 0; */
  bottom: 3px;
  right: -3px;
}

.disabled {
  opacity: 0.7;
}

.imgCropperGuide {
  width: 100%;
  margin-bottom: 3rem;
}
.triangulo_noself {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 11px solid #54678f;
  border-top: 0px solid #0000;
  border-bottom: 15px solid #0000;
  left: -5px;
  top: 16px;
  transform: rotate(90deg);
}

.triangulo_noself-chat {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #54678f;
  border-top: 0px solid #0000;
  border-bottom: 14px solid #0000;
  left: -2px;
  bottom: 15px;
  transform: rotate(90deg);
}

.pharragraph_message {
  width: 90%;
  min-width: 80px;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}

.doRedCam {
  background: #F60303;
  height: 48px;
  width: 48px !important;
  margin: auto;
  border-radius: 50%;
  margin-top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doRedCam img {
  height: 25px;
}

.MonitorClass {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 43px;
  height: 25px;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes opacityToggle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes changeImage {
  0% {
    background: url(./../loading-buffering.gif);
  }
  100% {
    background: url(./../checkmark.png);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(15px, 0);
  }
}

.main_box {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  text-align: center;
  font-size: 1em;
  font-family: Helvetica-light;
}

.MeetingPage {
  background-color: #f2f4f8;
  padding: 50px;
  width: 100%;
  max-width: 730px;
}

.textMessage {
  font-size: 1.1rem;
  color: gray;
}

.contain {
  /* position: relative; */
  width: 100%;
  height: calc(100% - (58px + 22px + 50px));
}

/* Zone Documents */
.documents-zone {
  position: relative;
  /* overflow-y: auto; */
  width: 100%;
  /* height: 34%; */
}

/* snapshot container */
.snapshot-row {
  position: relative;
  width: auto;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 28px;
}

.snapshot-col {
  width: calc(50% - 10px);
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* padding: 5px; */
}

.snapshot {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.icon {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 33px;
  background: white;
  color: #e2574c;
  border-radius: 50%;
  cursor: pointer;
}

.centeredUniqElement {
  margin-left: 51.5px;
}

/* Button Zone */
.zone-button {
  position: fixed;
  bottom: 32px;
  max-width: 411px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px 25px 0 0;
  background-color: #081e3d;
  width: 100%;
  /* position: relative; */
}

.iconCrop {
  width: 33px;
  height: 33px;
  object-fit: contain;
}

.textCard {
  color: #768191;
  font-size: 28px;
}

#small-modal-dk___BV_modal_content_,
#new-turn-modal___BV_modal_content_,
#cancel-modal___BV_modal_content_,
#Allowed-video___BV_modal_content_,
#Agent-not-connect___BV_modal_content_,
#Finalize-call___BV_modal_content_ {
  max-width: 640px;
  /* margin: 10px; */
  position: absolute;
  margin: auto;
  bottom: 105px;
  left: 50%;
  transform: translateX(-50%);
}

.cancelIcon {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 30px;
}

#small-modal-dk___BV_modal_content_,
#new-turn-modal___BV_modal_content_,
#cancel-modal___BV_modal_content_,
#Allowed-video___BV_modal_content_,
#Agent-not-connect___BV_modal_content_,
#Finalize-call___BV_modal_content_ {
  bottom: auto;
  top: 72.5%;
  transform: translate(-50%, -50%);
}

#Allowed-video___BV_modal_content_ {
  top: 50%;
}

.TitleZoned {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  max-width: fit-content;
  max-width: 411px;
  width: 100%;
  padding: 7px 0px;
  background-color: var(--color-white);
}

.size-buttons svg {
  fill: #00599d;
}

.size-buttons {
  margin: 0px auto;
  position: relative;
  display: block;
}

.icon-container {
  border-radius: 10px;
  margin: 10px;
  padding: 10px 0px;
  border: 1px solid #ececec;
  width: auto;
  color: rgb(114, 114, 114);
  background: #ececec;
}

.listItem {
  line-height: 20px;
}

.olContainer {
  margin: 0px 18px;
  margin-right: 20px;
}

.max-image-hold {
  height: auto;
  width: 100%;
  max-width: 70vw;
  max-height: 70vh;
  object-fit: cover;
}

.imgCropperGuide {
  width: 100%;
  margin-bottom: 3rem;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: 0;
}

.containerIcons {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #081e3d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleCard {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.circleCard div {
  cursor: pointer;
}

.textCard {
  color: #768191;
}

/* .Overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 199;
  background-color: ;
} */

.containerImage {
  height: 100%;
  width: 100%;
  max-height: calc(100% - 152px);
  max-width: 529px;
}

.containerImage img {
  /* This rule is very important, please don't ignore this */
  max-width: 370px;
  max-height: 370px;
  width: 100%;
  object-fit: cover;
}

.Background {
  background: #081e3d;
  height: calc(100% - 56px);
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.buttonTitle {
  font-size: 20.35px;
}

.iconCenteredArrow {
  right: 17.2px;
  top: 50%;
  transform: translateY(-50%);
}

.descP {
  white-space: nowrap !important;
}

.text-start {
  text-align: start;
}

.PopupConfig {
  position: absolute;
  top: -467px;
  right: -230px;
  z-index: 999;
  background-color: white;
  box-shadow: 10px 10px 42px rgb(0 0 0 / 27%);
  border-radius: 10px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20000000000;
  background-color: rgba(0, 0, 0, 0.507);
}

.DescDocs {
  font-size: 18px;
  line-height: 24px;

  /* RESPUESTAS AND ACCENT */

  color: #081e3d;
}

.fakeElementToScrollTwo {
  height: 50px;
}

.text-error {
  color: #e2574c;
}

.UploadFilesPage {
  background-color: #f2f4f8;
  /* padding: 1.5rem; */
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 621px;
}

.containerPage {
  padding: 0 1.5rem;
}

.svgLeft {
  left: 17.2px;
  transform: rotate(180deg) translateY(-51%);
  bottom: 50%;
  top: auto;
}

.SendTwo {
  max-width: 275px;
  margin: auto;
  display: block;
}

.container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 138px;
  padding-left: 138px;
  margin-right: auto;
  margin-left: auto;
}

.AuthMeds {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 65px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.AuthsBtn {
  margin: 100px auto;
  width: 404px;
  padding: 9px 3.5rem;
  line-height: 65px;
  font-weight: bold;
}

@media (max-width: 600px) {
  .contain {
    margin-top: 0px;
    height: calc(100% - (58px + 22px));
  }
}
</style>
